import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import Globals from '@/classes/Globals';
import Assets from '@/classes/Assets';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let NotFoundPage = class NotFoundPage extends JSPage {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
    }
    created() {
        super.created();
        Globals.setPageFound(false, true);
    }
    goBack() {
        Router.goBack();
    }
};
NotFoundPage = tslib_1.__decorate([
    Component
], NotFoundPage);
export default NotFoundPage;
