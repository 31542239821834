import BaseService from './BaseService';
import { Observable } from "rxjs";
export default class ResponseService {
    constructor() {
        this.path = 'responses';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    getEnums(name) {
        if (ResponseService.enums.length == 0) {
            let content = {};
            if (name)
                content.name = name;
            return BaseService.get(this.path + '/enums', content);
        }
        else {
            return new Observable((observer) => {
                observer.next(ResponseService.enums);
            });
        }
    }
}
ResponseService.enums = new Array();
