import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';
import InputField from '@/components/InputField/InputField.vue';
import Sentinel from '@/classes/Sentinel';
import Validator from '@/classes/Validator';
import Router from '@/classes/Router';
import Assets from '@/classes/Assets';
let LoginPage = class LoginPage extends Vue {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
        this.Assets = Assets;
        this.email = '';
        this.password = '';
        this.error = '';
    }
    created() {
        Sentinel.checkUser('/', '/dashboard');
    }
    doLogin() {
        if (this.validateForm()) {
            this.userService.login(this.email, this.password).subscribe((data) => {
                Sentinel.setUser(data);
                Router.goTo('/dashboard');
            }, (error) => {
                this.error = error.message;
            });
        }
    }
    validateForm() {
        var validated = true;
        if (this.email.trim().length == 0) {
            this.error = 'Por favor, introduce un correo electrónico.';
        }
        else if (!Validator.isValidEmail(this.email)) {
            this.error = 'El correo electrónico introducido no es válido.';
        }
        else if (this.password.trim().length == 0) {
            this.error = 'Por favor, introduce una contraseña.';
        }
        return !this.hasError();
    }
    hideError() {
        this.error = '';
    }
    hasError() {
        return this.error !== '';
    }
};
LoginPage = tslib_1.__decorate([
    Component({
        components: {
            InputField
        }
    })
], LoginPage);
export default LoginPage;
