import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
let MaterialMenuInfo = class MaterialMenuInfo extends Vue {
    okButtonClicked() {
        this.$emit('okButton');
    }
    cancelButtonClicked() {
        this.$emit('cancelButton');
        this.close();
    }
    close() {
        this.$emit('input', false);
    }
};
tslib_1.__decorate([
    Prop({ default: false })
], MaterialMenuInfo.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: 'green' })
], MaterialMenuInfo.prototype, "color", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialMenuInfo.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialMenuInfo.prototype, "canDelete", void 0);
tslib_1.__decorate([
    Prop({ default: 'Aceptar' })
], MaterialMenuInfo.prototype, "okButtonText", void 0);
tslib_1.__decorate([
    Prop({ default: 'Cancelar' })
], MaterialMenuInfo.prototype, "cancelButtonText", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialMenuInfo.prototype, "readonly", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialMenuInfo.prototype, "contentClass", void 0);
MaterialMenuInfo = tslib_1.__decorate([
    Component({
        components: {
            MaterialAvatar
        }
    })
], MaterialMenuInfo);
export default MaterialMenuInfo;
