import { Subject } from "rxjs";
export var CBlockerStateEnum;
(function (CBlockerStateEnum) {
    CBlockerStateEnum[CBlockerStateEnum["HIDDEN"] = 0] = "HIDDEN";
    CBlockerStateEnum[CBlockerStateEnum["LOADING"] = 1] = "LOADING";
    CBlockerStateEnum[CBlockerStateEnum["SUCCESS"] = 2] = "SUCCESS";
    CBlockerStateEnum[CBlockerStateEnum["ERROR"] = 3] = "ERROR";
    CBlockerStateEnum[CBlockerStateEnum["CONFIRM"] = 4] = "CONFIRM";
    CBlockerStateEnum[CBlockerStateEnum["INFO"] = 5] = "INFO";
})(CBlockerStateEnum || (CBlockerStateEnum = {}));
export class CBlocker {
    static setState(newState, message, forceState) {
        if (CBlocker.overloads < 0) {
            CBlocker.overloads = 0;
        }
        if (!CBlocker.isFinalState()) {
            if (newState == CBlockerStateEnum.HIDDEN) {
                CBlocker.overloads--;
            }
            else if (newState == CBlockerStateEnum.LOADING) {
                CBlocker.overloads++;
            }
            else {
                CBlocker.overloads = 0;
            }
            if (CBlocker.state == newState) {
                return;
            }
        }
        else {
            if (newState != CBlockerStateEnum.HIDDEN || !forceState) {
                return;
            }
            CBlocker.overloads = 0;
        }
        if (newState != CBlockerStateEnum.HIDDEN || (newState == CBlockerStateEnum.HIDDEN && CBlocker.overloads == 0)) {
            CBlocker.state = newState;
            CBlocker.message = message;
            CBlocker.cblockerSubject.next();
        }
    }
    static watchCBlocker() {
        return this.cblockerSubject.asObservable();
    }
    static getState() {
        return CBlocker.state;
    }
    static setMessage(message) {
        CBlocker.message = message;
    }
    static getMessage() {
        return CBlocker.message;
    }
    static setButtonsTexts(yesButtonText, noButtonText) {
        CBlocker.yesButtonText = yesButtonText ? yesButtonText : 'SI';
        CBlocker.noButtonText = noButtonText ? noButtonText : 'NO';
    }
    static getYesButtonText() {
        return CBlocker.yesButtonText;
    }
    static getNoButtonText() {
        return CBlocker.noButtonText;
    }
    static setButtonsActions(yesButtonAction, noButtonAction) {
        CBlocker.yesButtonAction = yesButtonAction ? yesButtonAction : () => { CBlocker.hide(true); };
        CBlocker.noButtonAction = noButtonAction ? noButtonAction : () => { CBlocker.hide(true); };
    }
    static getYesButtonAction() {
        return CBlocker.yesButtonAction;
    }
    static getNoButtonAction() {
        return CBlocker.noButtonAction;
    }
    static isHidden() { return CBlocker.state == CBlockerStateEnum.HIDDEN; }
    static isLoading() { return CBlocker.state == CBlockerStateEnum.LOADING; }
    static isSuccess() { return CBlocker.state == CBlockerStateEnum.SUCCESS; }
    static isError() { return CBlocker.state == CBlockerStateEnum.ERROR; }
    static isConfirm() { return CBlocker.state == CBlockerStateEnum.CONFIRM; }
    static isInfo() { return CBlocker.state == CBlockerStateEnum.INFO; }
    static isFinalState() { return !CBlocker.isHidden() && !CBlocker.isLoading(); }
    static show(state, milliseconds, message, callback) {
        CBlocker.setState(state, message);
        if (state == CBlockerStateEnum.SUCCESS || state == CBlockerStateEnum.ERROR) {
            let time = CBlocker.MAX_TIME;
            if (state == CBlockerStateEnum.ERROR) {
                time = CBlocker.MAX_ERROR_TIME;
            }
            if (milliseconds) {
                time = milliseconds;
            }
            setTimeout(() => {
                CBlocker.hide(true);
                if (callback) {
                    callback();
                }
            }, time);
        }
    }
    static hide(force) {
        CBlocker.setState(CBlockerStateEnum.HIDDEN, undefined, force);
    }
    static showLoading() { CBlocker.show(CBlockerStateEnum.LOADING); }
    static showSuccess(message, milliseconds, callback) {
        CBlocker.show(CBlockerStateEnum.SUCCESS, milliseconds, message, callback);
    }
    static showError(message, milliseconds, callback) {
        CBlocker.show(CBlockerStateEnum.ERROR, milliseconds, message, callback);
    }
    static showConfirm(message, onYesActionCallback, onNoActionCallback, yesButtonText, noButtonText) {
        CBlocker.show(CBlockerStateEnum.CONFIRM, undefined, message);
        CBlocker.setButtonsTexts(yesButtonText, noButtonText);
        CBlocker.setButtonsActions(onYesActionCallback, onNoActionCallback);
    }
    static showInfo(message, onSuccessCallback, successButtonText = 'Aceptar') {
        CBlocker.show(CBlockerStateEnum.INFO, undefined, message);
        CBlocker.setButtonsTexts(successButtonText);
        CBlocker.setButtonsActions(onSuccessCallback);
    }
}
CBlocker.MAX_TIME = 1500;
CBlocker.MAX_ERROR_TIME = 5000;
CBlocker.overloads = 0;
CBlocker.state = CBlockerStateEnum.HIDDEN;
CBlocker.message = '';
CBlocker.yesButtonText = '';
CBlocker.noButtonText = '';
CBlocker.yesButtonAction = () => { };
CBlocker.noButtonAction = () => { };
CBlocker.cblockerSubject = new Subject();
