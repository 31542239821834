import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import LightBoxSubscription from './LightBoxSubscription';
let LightBox = class LightBox extends Vue {
    constructor() {
        super(...arguments);
        this.mustShow = false;
        this.urls = new Array();
        this.actualIndex = 0;
    }
    created() {
        LightBoxSubscription.watch(this, (lightbox) => {
            this.urls = lightbox.urls ? lightbox.urls : '';
            this.mustShow = lightbox.show;
            this.actualIndex = lightbox.actualIndex;
        });
    }
    static show(url, actualIndex = 0) {
        var urls = new Array();
        if (!Array.isArray(url)) {
            urls.push(url);
        }
        else {
            urls = url;
        }
        LightBoxSubscription.show(urls, actualIndex);
    }
    close() {
        LightBoxSubscription.close();
    }
    previous() {
        this.actualIndex--;
        if (this.actualIndex < 0) {
            this.actualIndex = this.urls.length - 1;
        }
    }
    next() {
        this.actualIndex++;
        if (this.actualIndex >= this.urls.length) {
            this.actualIndex = 0;
        }
    }
};
LightBox = tslib_1.__decorate([
    Component
], LightBox);
export default LightBox;
