import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import MaterialUploader from '@/components/Base/MaterialUploader/MaterialUploader.vue';
import Helpers from '@/classes/Helpers';
import DeviceService from '@/services/DeviceService';
import CompanyService from '@/services/CompanyService';
import ResponseService from '@/services/ResponseService';
import { Company } from '@/models/Company';
import { Device } from '@/models/Device';
import JSPage from '@/classes/JSPage';
let AlertsPage = class AlertsPage extends JSPage {
    constructor() {
        super(...arguments);
        this.responseService = new ResponseService();
        this.tableName = 'Alertas';
        this.helpers = Helpers;
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Fecha', value: 'created_at', align: 'start', type: IDataTableHeaderType.DATETIME },
            { text: 'Dispositivo', value: 'id_device.strValue' },
        ];
        if (this.user.isAdmin()) {
            this.headers.push({ text: 'Empresa', value: 'id_company.strValue' });
        }
        this.headers.push({ text: 'Mensaje', value: 'message', align: 'start' }, { text: 'Localización', value: 'location', align: 'start', sortable: false });
    }
    setFilters() {
        this.filters = [
            { text: 'Fecha', value: 'created_at', content: undefined, toggle: true, type: 'date' },
            { text: 'Dispositivo', value: 'id_device', content: '', toggle: true, type: 'foreign',
                foreignService: new DeviceService(), field: 'name', order: 'name=ASC', class: Device }
        ];
        if (this.user.isAdmin()) {
            this.filters.push({ text: 'Empresa', value: 'id_company', content: '', toggle: true, type: 'foreign',
                foreignService: new CompanyService(), field: 'name', order: 'name=ASC', class: Company });
        }
    }
};
AlertsPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable,
            MaterialUploader
        }
    })
], AlertsPage);
export default AlertsPage;
