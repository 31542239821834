import { ForeignEntry } from './ForeignEntry';
export class Role {
    constructor(data) {
        this.id_company = new ForeignEntry();
        this.name = '';
        this.description = '';
        this.user = [];
        this.permissions = [];
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
}
