import BaseService from './BaseService';
export default class StatsService {
    constructor() {
        this.path = 'stats';
    }
    get() {
        return BaseService.get(this.path);
    }
}
StatsService.enums = new Array();
