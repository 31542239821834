import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { CBlocker } from './CBlocker';
let Blocker = class Blocker extends Vue {
    constructor() {
        super(...arguments);
        this.blocker = CBlocker;
        this.keyComponent = 1;
    }
    created() {
        CBlocker.watchCBlocker().subscribe(res => this.keyComponent++);
    }
    isHidden() {
        return this.blocker.isHidden();
    }
    onClick() {
        if (CBlocker.isError() || CBlocker.isSuccess()) {
            CBlocker.hide(true);
        }
    }
    yesButtonPressed() {
        let action = this.blocker.getYesButtonAction();
        action();
        CBlocker.hide(true);
    }
    noButtonPressed() {
        let action = this.blocker.getNoButtonAction();
        action();
        CBlocker.hide(true);
    }
};
Blocker = tslib_1.__decorate([
    Component
], Blocker);
export default Blocker;
