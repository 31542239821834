import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import FullCalendar from '@/components/FullCalendar/FullCalendar.vue';
import MaterialMenuInfo from '@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue';
import DatePicker from '@/components/Base/DatePicker/DatePicker.vue';
import ColorPicker from '@/components/Base/ColorPicker/ColorPicker.vue';
import TimePicker from '@/components/Base/TimePicker/TimePicker.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import { Schedule } from '@/models/Schedule';
import ScheduleTimeService from '@/services/ScheduleTimeService';
import { ScheduleTime } from '@/models/ScheduleTime';
import moment from 'moment';
import { CBlocker } from '@/components/Blocker/CBlocker';
import ScheduleService from '@/services/ScheduleService';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import JSPage from '@/classes/JSPage';
let GroupScheduleTab = class GroupScheduleTab extends JSPage {
    constructor() {
        super(...arguments);
        this.weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
        this.schedules = [];
        this.headers = [
            { text: 'Fecha inicio', value: 'startDateStr', type: IDataTableHeaderType.DATE },
            { text: 'Fecha fin', value: 'endDateStr', type: IDataTableHeaderType.DATE },
            { text: 'Prioridad', value: 'priority', align: 'center' },
            { text: 'Color', value: 'color', align: 'end' },
            { text: 'Actions', value: 'actions', align: 'end', sortable: false }
        ];
        this.schedule = new Schedule();
        this.scheduleService = new ScheduleService();
        this.scheduleTimeServicie = new ScheduleTimeService();
        this.enumWeekday = [];
        this.selectedScheduleTime = new ScheduleTime();
        this.selectedIndex = -1;
        this.scheduleDialog = false;
        this.dialog = false;
        this.msgError = '';
        this.sTimes = [];
    }
    get isEditing() { return this.selectedIndex != -1; }
    get scheduleTimes() {
        var events = this.schedule.scheduleTimes.map((scheduleTime) => (new ScheduleTime(scheduleTime).getCalendarEvent(this.schedule.color)));
        var prevSunday = [];
        events.filter((event) => event.item.weekday.value == 7).forEach((event) => {
            var ev = event.item.clone().getCalendarEvent(this.schedule.color);
            ev.start = moment(ev.start, 'YYYY-MM-DD HH:mm').subtract(7, 'd').format('YYYY-MM-DD HH:mm');
            ev.end = moment(ev.end, 'YYYY-MM-DD HH:mm').subtract(7, 'd').format('YYYY-MM-DD HH:mm');
            prevSunday.push(ev);
        });
        if (prevSunday.length > 0) {
            events = events.concat(prevSunday);
        }
        return events;
    }
    created() {
        super.created();
    }
    main() {
        this.getEnums();
        this.getSchedules();
    }
    getEnums() {
        this.scheduleTimeServicie.getEnums().subscribe((enums) => {
            this.enumWeekday = enums['Weekday'];
        });
    }
    getSchedules() {
        this.schedules = [];
        this.scheduleService.getAll('id_group==' + this.group.id.toString(), 'priority=ASC', undefined, 9999).subscribe((schedules) => {
            schedules.data.forEach((schedule) => {
                var sch = new Schedule(schedule);
                sch.scheduleTimes = sch.scheduleTimes.map((scheduleTime) => new ScheduleTime(scheduleTime));
                this.schedules.push(sch);
            });
        });
    }
    openDialogSchedule() {
        this.scheduleDialog = true;
        this.schedule.scheduleTimes.forEach((sT) => {
            this.sTimes.push(sT);
        });
        this.msgError = '';
    }
    closeDialogSchedule() {
        this.scheduleDialog = false;
    }
    newSchedule() {
        this.schedule = new Schedule();
        this.openDialogSchedule();
    }
    editItem(item) {
        this.schedule = item.clone();
        this.openDialogSchedule();
    }
    deleteItem(item) {
        CBlocker.showConfirm('¿Está seguro de que desea eliminar este horario?', () => {
            this.scheduleService.delete(item).subscribe(() => {
                CBlocker.showSuccess('Horario eliminado');
                this.getSchedules();
            });
        });
    }
    saveSchedule() {
        if (this.schedule.id > 0) {
            this.scheduleService.update(this.schedule).subscribe((schedule) => {
                this.schedule = new Schedule(schedule);
                this.closeDialogSchedule();
                this.getSchedules();
                this.$emit('input', this.group);
            });
        }
        else {
            this.schedule.id_group = this.group.id;
            this.scheduleService.add(this.schedule).subscribe((schedule) => {
                this.schedule = new Schedule(schedule);
                this.closeDialogSchedule();
                this.getSchedules();
                this.$emit('input', this.group);
            });
        }
    }
    getIntervalFormat(interval) {
        return interval.time;
    }
    showScheduleTime(event) {
        this.selectedScheduleTime = event.event.item;
        this.selectedIndex = this.schedule.scheduleTimes.findIndex((scheduleTime) => {
            return scheduleTime.weekday.value == this.selectedScheduleTime.weekday.value &&
                scheduleTime.start_time == this.selectedScheduleTime.start_time &&
                scheduleTime.end_time == this.selectedScheduleTime.end_time;
        });
        this.openDialog();
        event.nativeEvent.stopPropagation();
    }
    showTime(event) {
        this.selectedIndex = -1;
        if (this.dialog)
            return;
        let weekday = moment(event.date).day();
        this.selectedScheduleTime = new ScheduleTime({
            id: -1,
            id_schedule: this.schedule.id,
            weekday: weekday > 0 ? this.enumWeekday.find((wd) => wd.value == weekday) : 7,
            start_time: moment(event.hour + ':00', 'HH:mm').format('HH:mm'),
            end_time: moment(event.hour + ':00', 'HH:mm').add(1, 'hour').format('HH:mm')
        });
        this.openDialog();
    }
    saveScheduleTime(scheduleTime) {
        if (this.validate(scheduleTime)) {
            if (this.isEditing) {
                this.schedule.scheduleTimes.splice(this.selectedIndex, 1, scheduleTime);
            }
            else {
                this.schedule.scheduleTimes.push(scheduleTime);
            }
            this.closeDialog();
        }
    }
    deleteScheduleTime(scheduleTime) {
        CBlocker.showConfirm('¿Está seguro de que desea eliminar este elemento?', () => {
            this.schedule.scheduleTimes.splice(this.selectedIndex, 1);
            this.closeDialog();
        });
    }
    validate(scheduleTime) {
        if (scheduleTime.start_time == scheduleTime.end_time) {
            this.msgError = 'La hora de inicio y final deben de ser distintas';
            return false;
        }
        if (this.isEditing) {
            this.sTimes.splice(this.selectedIndex, 1);
        }
        var success = true;
        this.sTimes.forEach((sT) => {
            var stStart = moment(sT.start_ts, 'YYYY-MM-DD HH:mm');
            var stEnd = moment(sT.end_ts, 'YYYY-MM-DD HH:mm');
            var start = moment(scheduleTime.start_ts, 'YYYY-MM-DD HH:mm');
            var end = moment(scheduleTime.end_ts, 'YYYY-MM-DD HH:mm');
            success = success && (!start.isBetween(stStart, stEnd) && !end.isBetween(stStart, stEnd) &&
                !stStart.isBetween(start, end) && !stEnd.isBetween(start, end));
        });
        if (!success) {
            this.msgError = 'El intervalo horario seleccionado corta a otros intervalos';
        }
        return success;
    }
    openDialog() {
        this.dialog = true;
        this.msgError = '';
    }
    closeDialog() {
        this.dialog = false;
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], GroupScheduleTab.prototype, "group", void 0);
GroupScheduleTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable, FullCalendar, MaterialMenuInfo, DatePicker, ColorPicker, TimePicker
        }
    })
], GroupScheduleTab);
export default GroupScheduleTab;
