import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import MenuItem from '@/components/Base/MenuItem/MenuItem.vue';
import MenuItemSubGroup from '@/components/Base/MenuItem/MenuItemSubGroup/MenuItemSubGroup.vue';
let MenuItemGroup = class MenuItemGroup extends Vue {
    constructor() {
        super(...arguments);
        this.expand_locations = true;
    }
    onRouteChanged(newValue, oldValue) {
        this.checkExpandedItem();
    }
    created() {
        this.checkExpandedItem();
    }
    checkExpandedItem() {
        var children = this.item.children.map((child) => {
            return child.to;
        });
        this.expand_locations = children.includes(this.$route.path);
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], MenuItemGroup.prototype, "item", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MenuItemGroup.prototype, "subGroup", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MenuItemGroup.prototype, "text", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MenuItemGroup.prototype, "role", void 0);
tslib_1.__decorate([
    Watch('$route')
], MenuItemGroup.prototype, "onRouteChanged", null);
MenuItemGroup = tslib_1.__decorate([
    Component({
        components: {
            MenuItem,
            MenuItemSubGroup
        },
        computed: mapState([
            'barColor'
        ])
    })
], MenuItemGroup);
export default MenuItemGroup;
