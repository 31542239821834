export class GroupPermission {
    constructor(data) {
        this.devices = [];
        this.groups = [];
        this.permissions = [];
        if (data) {
            Object.assign(this, data);
        }
    }
}
