import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import MaterialUploader from '@/components/Base/MaterialUploader/MaterialUploader.vue';
import Helpers from '@/classes/Helpers';
import ResponseService from '@/services/ResponseService';
import JSPage from '@/classes/JSPage';
let DeviceAlertsTab = class DeviceAlertsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.responseService = new ResponseService();
        this.tableName = 'Alertas';
        this.helpers = Helpers;
        this.headers = [
            { text: 'Fecha', value: 'created_at', align: 'start', type: IDataTableHeaderType.DATETIME, maxWidth: 160 },
            { text: 'Mensaje', value: 'message', align: 'start' }
        ];
    }
    created() {
        super.created();
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], DeviceAlertsTab.prototype, "device", void 0);
DeviceAlertsTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable,
            MaterialUploader
        }
    })
], DeviceAlertsTab);
export default DeviceAlertsTab;
