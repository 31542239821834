import moment from 'moment';
import { CalendarEvent } from '@/classes/vuetify/CalendarEvent';
export class ScheduleTime {
    constructor(data) {
        this.id = -1;
        this.weekday = 1;
        this.start_time = '';
        this.end_time = '';
        this.start_ts = '';
        this.end_ts = '';
        if (data) {
            Object.assign(this, data);
            this.refresh();
        }
    }
    clone() {
        return Object.assign(new ScheduleTime(), this);
    }
    getStartTime() {
        if (!this.start_time)
            return '';
        let today = moment();
        let weekday = today.weekday() > 0 ? today.weekday() : 7;
        let wk = this.weekday instanceof Object ? this.weekday.value : this.weekday;
        let diff = weekday - wk;
        let weekdayDate = moment().subtract(diff, 'days').format('YYYY-MM-DD');
        return weekdayDate + ' ' + this.start_time;
    }
    getDate() {
        let today = moment();
        let weekday = today.weekday() > 0 ? today.weekday() : 7;
        let wk = this.weekday instanceof Object ? this.weekday.value : this.weekday;
        let diff = Math.abs(weekday - wk);
        let weekdayDate = moment().subtract(diff, 'days').format('YYYY-MM-DD');
        return weekdayDate;
    }
    getEndTime() {
        if (!this.end_time)
            return '';
        let today = moment();
        let weekday = today.weekday() > 0 ? today.weekday() : 7;
        let wk = this.weekday instanceof Object ? this.weekday.value : this.weekday;
        let diff = weekday - wk;
        if (moment(this.start_time.toString(), 'HH:mm').isAfter(moment(this.end_time.toString(), 'HH:mm'))) {
            diff--;
        }
        let weekdayDate = moment().subtract(diff, 'days').format('YYYY-MM-DD');
        return weekdayDate + ' ' + this.end_time;
    }
    getCalendarEvent(color) {
        var event = new CalendarEvent();
        event.start = this.getStartTime();
        event.end = this.getEndTime();
        event.color = color;
        event.item = this;
        return event;
    }
    refresh() {
        if (this.start_time != '') {
            this.start_time = moment(this.start_time, 'HH:mm').format('HH:mm');
        }
        if (this.end_time != '') {
            this.end_time = moment(this.end_time, 'HH:mm').format('HH:mm');
        }
        this.start_ts = this.getStartTime();
        this.end_ts = this.getEndTime();
    }
    toNormalize() {
        let scheduleTime = Object.assign({}, this);
        if (scheduleTime.weekday instanceof Object) {
            scheduleTime.weekday = scheduleTime.weekday.value;
        }
        delete (scheduleTime.start_ts);
        delete (scheduleTime.end_ts);
        delete (scheduleTime.overflowed);
        return scheduleTime;
    }
}
