import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import GroupService from '@/services/GroupService';
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import GroupMainTab from './Tabs/GroupMainTab/GroupMainTab.vue';
import GroupDevicesTab from './Tabs/GroupDevicesTab/GroupDevicesTab.vue';
import GroupScheduleTab from './Tabs/GroupScheduleTab/GroupScheduleTab.vue';
import { Permission } from '@/models/Permission';
import JSPage from '@/classes/JSPage';
import { Group } from '@/models/Group';
let GroupPage = class GroupPage extends JSPage {
    constructor() {
        super(...arguments);
        this.tab = '';
        this.groupService = new GroupService();
        this.group = {};
        this.tabs = new Array();
    }
    onGroupChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Grupo', icon: 'mdi-group', href: 'main-tab' });
        if (this.group.id) {
            this.tabs.push({ title: 'Dispositivos', icon: 'mdi-router', href: 'devices-tab' });
            if (this.user.hasPermission(Permission.PERMISSION_DEVICE_EDIT_SCHEDULE, this.group)) {
                this.tabs.push({ title: 'Horario', icon: 'mdi-calendar', href: 'schedule-tab' });
            }
        }
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getGroup();
        }
        else {
            if (id !== 'new') {
                this.cancel();
            }
        }
    }
    getGroup() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.groupService.get(id).subscribe((group) => {
                this.group = new Group(group);
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.group.id) {
            this.groupService.add(this.group).subscribe((group) => {
                this.group = new Group(group);
            }, (error) => { });
        }
        else {
            this.groupService.update(this.group).subscribe((group) => {
                this.group = new Group(group);
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onGroupUpdate(event) {
        this.group = new Group(event);
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('group')
], GroupPage.prototype, "onGroupChanged", null);
GroupPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, Tabs,
            GroupMainTab, GroupDevicesTab, GroupScheduleTab
        }
    })
], GroupPage);
export default GroupPage;
