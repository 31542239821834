import { EnumEntry } from "./EnumEntry";
import { ForeignEntry } from "./ForeignEntry";
export class Device {
    constructor(data) {
        this.id_company = new ForeignEntry();
        this.name = '';
        this.sim = '';
        this.password = '';
        this.location = '';
        this.id_group = new ForeignEntry();
        this.note = '';
        this.state = '';
        this.isOn = false;
        this.isOn_html = '';
        this.state_html = '';
        this.status = new EnumEntry();
        this.in_test_mode = new EnumEntry();
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
        if (this.state) {
            switch (this.state) {
                case 'OPEN':
                    this.state_html = '<i class="mdi mdi-valve-open red--text text--lighten-1 mr-2"></i><span class="red--text text--lighten-1">Abierto</span>';
                    break;
                case 'CLOSE':
                    this.state_html = '<i class="mdi mdi-valve-closed green--text text--lighten-1 mr-2"></i><span class="green--text text--lighten-1">Cerrado</span>';
                    break;
                default:
                    this.state_html = '<i class="mdi mdi-help blue-grey--text mr-2"></i><span class="blue-grey--text">Desconocido</span>';
            }
        }
        if (this.isOn) {
            this.isOn_html = '<i class="mdi mdi-lightbulb-on-outline yellow--text text--darken-4 mr-2"></i><span class="yellow--text text--darken-4">Encendido</span>';
        }
        else {
            this.isOn_html = '<i class="mdi mdi-lightbulb black--text text--lighten-1 mr-2"></i><span class="black--text text--lighten-1">Apagado</span>';
        }
    }
    toNormalize() {
        let device = Object.assign({}, this);
        if (device.status instanceof Object) {
            device.status = device.status.value;
        }
        if (device.in_test_mode instanceof Object) {
            device.in_test_mode = device.in_test_mode.value;
        }
        delete (device.state);
        delete (device.state_html);
        delete (device.isOn);
        delete (device.isOn_html);
        if (device.in_test_mode instanceof Object) {
            device.in_test_mode = device.in_test_mode.value;
        }
        if (device.in_test_mode === -1) {
            delete (device.in_test_mode);
        }
        return device;
    }
}
