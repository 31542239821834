import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import DeviceSchedule from '@/components/DeviceSchedule/DeviceSchedule.vue';
import InputField from '@/components/InputField/InputField.vue';
import DeviceService from '@/services/DeviceService';
import GroupService from '@/services/GroupService';
import { ForeignEntry } from '@/models/ForeignEntry';
import CompanyService from '@/services/CompanyService';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let DeviceMainTab = class DeviceMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.deviceService = new DeviceService();
        this.groupService = new GroupService();
        this.companyService = new CompanyService();
        this.groups = [];
        this.companies = [];
        this.enumStatus = [];
    }
    onCompanyChanged() {
        this.getGroups();
    }
    onDeviceChanged() {
        this.getGroups();
        this.getCompanies();
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.getGroups();
        this.getCompanies();
    }
    getEnums() {
        this.deviceService.getEnums().subscribe((enums) => {
            this.enumStatus = enums['Status'];
            if (!this.device.id) {
                this.device.status = this.enumStatus[0];
            }
        });
    }
    getGroups() {
        if (this.device && this.device.id_company) {
            var id_company = this.device.id_company instanceof Object ? this.device.id_company.value : this.device.id_company;
            this.groupService.getAll("id_company==" + id_company.toString(), 'name=ASC', undefined, 9999).subscribe((groups) => {
                this.groups = [];
                groups.data.forEach((group) => this.groups.push(new ForeignEntry(group.id, group.name)));
            });
        }
    }
    getCompanies() {
        this.companyService.getAll(undefined, undefined, undefined, 9999).subscribe((companies) => {
            this.companies = [];
            companies.data.forEach((company) => this.companies.push(new ForeignEntry(company.id, company.name)));
            if (!this.device.id && this.companies.length > 0) {
                this.device.id_company = this.companies[0];
            }
        });
    }
    update() {
        let d = this.device;
        if (this.id_company > 0) {
            d.id_company = new ForeignEntry(this.id_company);
        }
        if (d) {
            if (!d.id) {
                this.deviceService.add(d).subscribe((device) => {
                    this.$router.replace(device.id.toString());
                }, (error) => {
                });
            }
            else {
                this.deviceService.update(d).subscribe((device) => {
                    this.$emit('update', device);
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], DeviceMainTab.prototype, "device", void 0);
tslib_1.__decorate([
    Watch('device.id_company')
], DeviceMainTab.prototype, "onCompanyChanged", null);
tslib_1.__decorate([
    Watch('device')
], DeviceMainTab.prototype, "onDeviceChanged", null);
DeviceMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField, DeviceSchedule
        }
    })
], DeviceMainTab);
export default DeviceMainTab;
