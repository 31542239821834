import BaseService from './BaseService';
import { Observable } from "rxjs";
import { User } from '@/models/User';
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class UserService {
    constructor() {
        this.path = 'users';
    }
    login(email, password) {
        return BaseService.post(this.path + '/login', {
            email: email,
            password: password,
            isAdmin: true
        }, false);
    }
    recoverPassword(email) {
        return BaseService.post('recover_password', {
            email: email,
            base_path: window.location.origin + '/reset/'
        }, false);
    }
    resetPassword(token, password, repassword) {
        return BaseService.post('reset_password', {
            token: token,
            password: password,
            re_password: repassword
        }, false);
    }
    confirmRegistration(token) {
        return BaseService.post(this.path + '/confirm_registration', {
            token: token
        }, false);
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    add(user) {
        CBlocker.showLoading();
        let content = new User(user).toNormalize();
        return new Observable((observer) => {
            BaseService.post(this.path, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Usuario Añadido');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(user) {
        CBlocker.showLoading();
        let content = new User(user).toNormalize();
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + user.id, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('¡Actualizado!');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    delete(user) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.delete(this.path + '/' + user.id, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Usuario Eliminado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums(name) {
        if (UserService.enums.length == 0) {
            let content = {};
            if (name)
                content.name = name;
            return BaseService.get(this.path + '/enums', content);
        }
        else {
            return new Observable((observer) => {
                observer.next(UserService.enums);
            });
        }
    }
}
UserService.enums = new Array();
