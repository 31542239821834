import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import CompanyService from '@/services/CompanyService';
import { User } from '@/models/User';
import CompanyMainTab from './Tabs/CompanyMainTab/CompanyMainTab.vue';
import CompanyDevicesTab from './Tabs/CompanyDevicesTab/CompanyDevicesTab.vue';
import CompanyUsersTab from './Tabs/CompanyUsersTab/CompanyUsersTab.vue';
import CompanyGroupsTab from './Tabs/CompanyGroupsTab/CompanyGroupsTab.vue';
import CompanyAlertsTab from './Tabs/CompanyAlertsTab/CompanyAlertsTab.vue';
import JSPage from '@/classes/JSPage';
let CompanyPage = class CompanyPage extends JSPage {
    constructor() {
        super(...arguments);
        this.tab = '';
        this.companyService = new CompanyService();
        this.company = {};
        this.enumStatus = {};
        this.tabs = new Array();
        this.User = User;
    }
    onCompanyChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Empresa', icon: 'mdi-domain', href: 'main-tab' });
        if (this.company.id && this.user.isAdmin()) {
            this.tabs.push({ title: 'Dispositivos', icon: 'mdi-router', href: 'devices-tab' });
            this.tabs.push({ title: 'Alertas', icon: 'mdi-alert', href: 'alerts-tab' });
            this.tabs.push({ title: 'Grupos', icon: 'mdi-group', href: 'groups-tab' });
            this.tabs.push({ title: 'Técnicos', icon: 'mdi-account-group', href: 'users-tab' });
            this.tabs.push({ title: 'Administradores', icon: 'mdi-account-tie', href: 'admins-tab' });
        }
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.company.id = parseInt(id);
            this.getCompany();
        }
        else {
            if (id !== 'new') {
                if (!this.user.isAdmin()) {
                    this.getCompany();
                }
                else {
                    this.cancel();
                }
            }
        }
    }
    getEnums() {
        this.companyService.getEnums().subscribe((enums) => {
            this.enumStatus = enums['Status'];
            if (!this.company.id) {
                this.company.status = this.enumStatus[0];
            }
        });
    }
    getCompany() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (!id && !this.user.isAdmin()) {
            id = this.user.id_company.value;
        }
        if (id) {
            this.companyService.get(id).subscribe((company) => {
                this.company = company;
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    cancel() {
        this.$router.back();
    }
    onCompanyUpdate(event) {
        this.company = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('company')
], CompanyPage.prototype, "onCompanyChanged", null);
CompanyPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, Tabs,
            CompanyMainTab, CompanyDevicesTab, CompanyUsersTab, CompanyGroupsTab, CompanyAlertsTab
        }
    })
], CompanyPage);
export default CompanyPage;
