import Vue from "vue";
import Sentinel from "./Sentinel";
export default class JSPage extends Vue {
    constructor() {
        super(...arguments);
        this.user = Sentinel.getUser(false, '/');
    }
    created() {
        if (this.user.isLogged()) {
            this.main();
        }
        Sentinel.watchUser(this, (user) => {
            this.user = user;
            if (this.user.isLogged()) {
                this.main();
            }
        });
    }
    main() { }
}
