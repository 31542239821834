import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialMenuInfo from "@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue";
import moment from 'moment';
let FullCalendar = class FullCalendar extends Vue {
    constructor() {
        super(...arguments);
        this.actualYear = Number.parseInt(moment().format('YYYY'));
        this.weekdays = [1, 2, 3, 4, 5, 6, 0];
    }
    getMonthTitle(month) {
        var monthStr = moment([this.actualYear, month - 1, 1]).locale('es').format('MMMM').toString();
        monthStr = monthStr.substr(0, 1).toUpperCase() + monthStr.substr(1);
        return monthStr;
    }
    getStartDateForMonth(month) {
        return moment([this.actualYear, month - 1, 1]).format('YYYY-MM-DD');
    }
    getEventColor(event) {
        return event.color;
    }
    getEvents(date, month) {
        var dateWeekday = moment(date).weekday();
        var st = [];
        var priority = 0;
        this.schedules.forEach((schedule) => {
            if (schedule.scheduleTimes.filter((scheduleTime) => scheduleTime.weekday.value % 7 == dateWeekday).length > 0 &&
                moment(schedule.start_date).isSameOrBefore(date) &&
                moment(schedule.end_date).isSameOrAfter(date)) {
                if (priority == 0) {
                    priority = schedule.priority;
                }
                if (schedule.priority === priority) {
                    st.push(schedule);
                }
            }
        });
        return st;
    }
    getDayColor(date, month) {
        var dateWeekday = moment(date).weekday();
        var dateMonth = moment(date).month();
        if (dateMonth != month - 1) {
            return 'grey lighten-2';
        }
        return '';
    }
    isToday(date) {
        return moment().isSame(date, 'day');
    }
    previousYear() {
        this.actualYear--;
    }
    nextYear() {
        this.actualYear++;
    }
};
tslib_1.__decorate([
    Prop({ default: 'Calendario' })
], FullCalendar.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: [] })
], FullCalendar.prototype, "schedules", void 0);
FullCalendar = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialMenuInfo,
        }
    })
], FullCalendar);
export default FullCalendar;
