import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import GroupService from '@/services/GroupService';
import CompanyService from '@/services/CompanyService';
import { Company } from '@/models/Company';
import JSPage from '@/classes/JSPage';
let GroupsPage = class GroupsPage extends JSPage {
    constructor() {
        super(...arguments);
        this.groupService = new GroupService();
        this.tableName = 'Grupos';
        this.enumStatus = {};
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' }
        ];
        if (this.user.isAdmin()) {
            this.headers.push({ text: 'Empresa', value: 'id_company.strValue' });
        }
        this.headers.push({ text: 'Dispositivos', value: 'devices.length', align: 'center', sortable: false }, { text: 'Descripción', value: 'description', align: 'start', sortable: false }, { text: '', value: 'actions', align: 'end', sortable: false });
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
        if (this.user.isAdmin()) {
            this.filters.push({ text: 'Empresa', value: 'id_company', content: '', toggle: true, type: 'foreign',
                foreignService: new CompanyService(), field: 'name', order: 'name=ASC', class: Company });
        }
    }
};
GroupsPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], GroupsPage);
export default GroupsPage;
