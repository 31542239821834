import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import { ForeignEntry } from '@/models/ForeignEntry';
import CompanyService from '@/services/CompanyService';
import ProvinceService from '@/services/ProvinceService';
import JSPage from '@/classes/JSPage';
let CompanyMainTab = class CompanyMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.companyService = new CompanyService();
        this.provinceService = new ProvinceService();
        this.provinces = [];
        this.enumStatus = [];
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.getProvinces();
    }
    getEnums() {
        this.companyService.getEnums().subscribe((enums) => {
            this.enumStatus = enums['Status'];
            if (!this.company.id) {
                this.company.status = this.enumStatus[0];
            }
        });
    }
    getProvinces() {
        this.provinceService.getAll(undefined, undefined, undefined, 9999).subscribe((provinces) => {
            this.provinces = [];
            provinces.data.forEach((province) => this.provinces.push(new ForeignEntry(province.id, province.name)));
        });
    }
    update() {
        let c = this.company;
        if (c) {
            if (!c.id) {
                this.companyService.add(c).subscribe((company) => {
                    this.$router.replace(company.id.toString());
                }, (error) => {
                });
            }
            else {
                this.companyService.update(c).subscribe((company) => {
                    this.$emit('update', company);
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], CompanyMainTab.prototype, "company", void 0);
CompanyMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField
        }
    })
], CompanyMainTab);
export default CompanyMainTab;
