import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import DeviceService from '@/services/DeviceService';
import GroupService from '@/services/GroupService';
import { Group } from '@/models/Group';
import { Device } from '@/models/Device';
import JSPage from '@/classes/JSPage';
let CompanyDevicesTab = class CompanyDevicesTab extends JSPage {
    constructor() {
        super(...arguments);
        this.deviceService = new DeviceService();
        this.headers = [];
        this.filters = [];
        this.Device = Device;
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' }
        ];
        this.headers.push({ text: 'Nº SIM', value: 'sim', align: 'start' }, { text: 'Location', value: 'location', align: 'start' }, { text: 'Grupo', value: 'id_group.strValue', align: 'start' });
        this.headers.push({ text: 'Estado Relé', value: 'state_html', type: IDataTableHeaderType.HTML, sortable: false }, { text: 'Alimentación', value: 'isOn_html', type: IDataTableHeaderType.HTML, sortable: false });
        if (!this.user.isUser()) {
            this.headers.push({ text: '', value: 'actions', align: 'end', sortable: false });
        }
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
        this.filters.push({ text: 'Nº SIM', value: 'sim', content: '', toggle: true, type: 'text' }, { text: 'Location', value: 'location', content: '', toggle: true, type: 'text' }, { text: 'Grupo', value: 'id_group', content: '', toggle: true, type: 'foreign',
            filter: 'id_company==' + this.company.id.toString(),
            foreignService: new GroupService(), field: 'name', order: 'name=ASC', class: Group });
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], CompanyDevicesTab.prototype, "company", void 0);
CompanyDevicesTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], CompanyDevicesTab);
export default CompanyDevicesTab;
