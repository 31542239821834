import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import { User } from '@/models/User';
import UserService from '@/services/UserService';
import CompanyService from '@/services/CompanyService';
import { ForeignEntry } from '@/models/ForeignEntry';
import Router from '@/classes/Router';
import RoleService from '@/services/RoleService';
import JSPage from '@/classes/JSPage';
let UserAccountTab = class UserAccountTab extends JSPage {
    constructor() {
        super(...arguments);
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.userService = new UserService();
        this.companyService = new CompanyService();
        this.roleService = new RoleService();
        this.companies = [];
        this.roles = [];
        this.showPass = false;
    }
    onUserCompanyChanged() {
        if (this.user_.id <= 0) {
            this.user_.id_role = new ForeignEntry();
        }
        this.getRoles();
    }
    created() {
        super.created();
    }
    main() {
        this.getCompanies();
        this.getRoles();
    }
    getCompanies() {
        this.companyService.getAll(undefined, 'name=asc', undefined, 9999).subscribe((companies) => {
            this.companies = [];
            companies.data.forEach((company) => this.companies.push(new ForeignEntry(company.id, company.name)));
        });
    }
    getRoles() {
        var id_company = this.id_company;
        if (id_company === 0) {
            if (this.user.isAdmin()) {
                id_company = this.user_.id_company instanceof Object ? this.user_.id_company.value : this.user_.id_company;
            }
            else {
                id_company = this.user.id_company.value;
            }
        }
        this.roleService.getAll('id_company==' + id_company, 'name=asc;', undefined, 9999).subscribe((roles) => {
            this.roles = [];
            roles.data.forEach((role) => this.roles.push(new ForeignEntry(role.id, role.name)));
        });
    }
    update() {
        let u = this.user_;
        if (this.id_company > 0) {
            u.id_company = new ForeignEntry(this.id_company);
        }
        if (u) {
            if (!u.id) {
                this.userService.add(u).subscribe((user_) => {
                    this.$router.replace(user_.id.toString());
                }, (error) => {
                });
            }
            else {
                this.userService.update(u).subscribe((user_) => {
                    this.$emit('input', new User(user_));
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], UserAccountTab.prototype, "user_", void 0);
tslib_1.__decorate([
    Watch('user_.id_company')
], UserAccountTab.prototype, "onUserCompanyChanged", null);
UserAccountTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField
        }
    })
], UserAccountTab);
export default UserAccountTab;
