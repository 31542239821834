import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import DeviceService from '@/services/DeviceService';
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import DeviceMainTab from './Tabs/DeviceMainTab/DeviceMainTab.vue';
import DeviceAlertsTab from './Tabs/DeviceAlertsTab/DeviceAlertsTab.vue';
import DeviceOpsTab from './Tabs/DeviceOpsTab/DeviceOpsTab.vue';
import DeviceScheduleTab from './Tabs/DeviceScheduleTab/DeviceScheduleTab.vue';
import { Device } from '@/models/Device';
import { Permission } from '@/models/Permission';
import JSPage from '@/classes/JSPage';
let DevicePage = class DevicePage extends JSPage {
    constructor() {
        super(...arguments);
        this.deviceService = new DeviceService();
        this.device = {};
        this.enumStatus = {};
        this.tabs = new Array();
        this.tab = '';
    }
    onDeviceChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Dispositivo', icon: 'mdi-router-wireless', href: 'main-tab' });
        if (this.device.id) {
            this.tabs.push({ title: 'Alertas', icon: 'mdi-alert', href: 'alerts-tab' });
            this.tabs.push({ title: 'Operativa', icon: 'mdi-cog', href: 'ops-tab' });
            if (this.user.hasPermission(Permission.PERMISSION_DEVICE_EDIT_SCHEDULE, this.device)) {
                this.tabs.push({ title: 'Horario', icon: 'mdi-calendar', href: 'schedule-tab' });
            }
        }
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getDevice();
        }
        else {
            if (id !== 'new') {
                this.cancel();
            }
        }
    }
    getEnums() {
        this.deviceService.getEnums().subscribe((enums) => {
            this.enumStatus = enums['Status'];
            if (!this.device.id) {
                this.device.status = this.enumStatus[0];
            }
        });
    }
    getDevice() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.deviceService.get(id).subscribe((device) => {
                this.device = new Device(device);
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.device.id) {
            this.deviceService.add(this.device).subscribe((device) => {
                this.device = new Device(device);
            }, (error) => { });
        }
        else {
            this.deviceService.update(this.device).subscribe((device) => {
                this.device = new Device(device);
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onDeviceUpdate(event) {
        this.device = new Device(event);
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('device')
], DevicePage.prototype, "onDeviceChanged", null);
DevicePage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, Tabs,
            DeviceMainTab, DeviceAlertsTab, DeviceOpsTab, DeviceScheduleTab
        }
    })
], DevicePage);
export default DevicePage;
