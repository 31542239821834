import Vue from 'vue';
import Vuex from 'vuex';
import User from './User.module';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        sidebarState: window.innerWidth > 1024,
        excludedPages: ['/'],
    },
    getters: {},
    mutations: {
        setSidebarState(state, payload) {
            state.sidebarState = payload;
            setTimeout(() => globalThis.window.dispatchEvent(new Event('resize')), 300);
        },
    },
    actions: {},
    modules: {
        User,
    },
});
