import moment from 'moment';
import { ScheduleTime } from './ScheduleTime';
export class Schedule {
    constructor(data) {
        this.id = -1;
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().format('YYYY-MM-DD');
        this.priority = 10;
        this.color = '#c99aea';
        this.scheduleTimes = [];
        if (data) {
            Object.assign(this, data);
            if (this.scheduleTimes) {
                for (let i = 0; i < this.scheduleTimes.length; i++) {
                    this.scheduleTimes[i] = new ScheduleTime(this.scheduleTimes[i]);
                }
            }
        }
    }
    clone() {
        var clone = Object.assign(new Schedule(), this);
        clone.scheduleTimes = [];
        this.scheduleTimes.forEach((scheduleTime) => {
            clone.scheduleTimes.push(scheduleTime.clone());
        });
        return clone;
    }
    get startDateStr() {
        return moment(this.start_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
    get endDateStr() {
        return moment(this.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
    toNormalize() {
        let schedule = Object.assign({}, this);
        for (let i = 0; i < this.scheduleTimes.length; i++) {
            this.scheduleTimes[i] = this.scheduleTimes[i].toNormalize();
        }
        return schedule;
    }
}
