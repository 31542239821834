import * as tslib_1 from "tslib";
import Router from '@/classes/Router';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let Tabs = class Tabs extends Vue {
    constructor() {
        super(...arguments);
        // DOM reference for the window object
        this.window = globalThis.window;
    }
    onParamsChanged() {
        if (this.tabDelegate) {
            this.tabDelegate.main();
        }
    }
    created() {
        if (this.$router.currentRoute.hash === '' && this.$router.currentRoute.params.id != 'new') {
            this.$router.replace('#main-tab');
        }
        if (this.tabDelegate) {
            this.tabDelegate.tab = 'main-tab';
        }
    }
    isAllowed(tab) {
        return true;
    }
    getTotalTabs() {
        return this.tabs.length;
    }
    gotoTab(t) {
        Router.addParamToURL('tab', t.href);
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], Tabs.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: [] })
], Tabs.prototype, "tabs", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], Tabs.prototype, "tabDelegate", void 0);
tslib_1.__decorate([
    Watch('$route.params')
], Tabs.prototype, "onParamsChanged", null);
Tabs = tslib_1.__decorate([
    Component
], Tabs);
export default Tabs;
