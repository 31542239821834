import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MaterialMenuInfo from '@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue';
import DatePicker from '@/components/Base/DatePicker/DatePicker.vue';
import ColorPicker from '@/components/Base/ColorPicker/ColorPicker.vue';
import TimePicker from '@/components/Base/TimePicker/TimePicker.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import { Schedule } from '@/models/Schedule';
import ScheduleTimeService from '@/services/ScheduleTimeService';
import { ScheduleTime } from '@/models/ScheduleTime';
import moment from 'moment';
import ScheduleService from '@/services/ScheduleService';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import Trilean, { TrileanValue } from '@/classes/Trilean';
let DeviceSchedule = class DeviceSchedule extends Vue {
    constructor() {
        super(...arguments);
        this.weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
        this.schedules = [];
        this.headers = [
            { text: 'Fecha inicio', value: 'startDateStr', type: IDataTableHeaderType.DATE },
            { text: 'Fecha fin', value: 'endDateStr', type: IDataTableHeaderType.DATE },
            { text: 'Prioridad', value: 'priority', align: 'center' },
            { text: 'Color', value: 'color', align: 'end' },
            { text: 'Actions', value: 'actions', align: 'end', sortable: false }
        ];
        this.schedule = new Schedule();
        this.scheduleService = new ScheduleService();
        this.scheduleTimeServicie = new ScheduleTimeService();
        this.enumWeekday = [];
        this.selectedScheduleTime = new ScheduleTime();
        this.selectedIndex = -1;
        this.scheduleDialog = false;
        this.dialog = false;
        this.msgError = '';
        this.sTimes = [];
        this.scheduleByGroup = new Trilean();
    }
    get isEditing() { return this.selectedIndex != -1; }
    get scheduleTimes() {
        var events = this.schedule.scheduleTimes.map((scheduleTime) => (new ScheduleTime(scheduleTime).getCalendarEvent(this.schedule.color)));
        var prevSunday = [];
        events.filter((event) => event.item.weekday.value == 7).forEach((event) => {
            var ev = event.item.clone().getCalendarEvent(this.schedule.color);
            ev.start = moment(ev.start, 'YYYY-MM-DD HH:mm').subtract(7, 'd').format('YYYY-MM-DD HH:mm');
            ev.end = moment(ev.end, 'YYYY-MM-DD HH:mm').subtract(7, 'd').format('YYYY-MM-DD HH:mm');
            prevSunday.push(ev);
        });
        if (prevSunday.length > 0) {
            events = events.concat(prevSunday);
        }
        return events;
    }
    onDeviceChanged() {
        this.getEnums();
        this.getSchedules();
    }
    created() {
        this.getEnums();
        this.getSchedules();
    }
    getEnums() {
        this.scheduleTimeServicie.getEnums().subscribe((enums) => {
            this.enumWeekday = enums['Weekday'];
        });
    }
    getSchedules() {
        if (this.device && this.device.id) {
            this.scheduleService.getAll('id_device==' + this.device.id.toString(), 'priority=ASC', undefined, 9999).subscribe((schedules) => {
                this.schedules = [];
                if (schedules.data.length > 0) {
                    this.scheduleByGroup.value = TrileanValue.FALSE;
                    schedules.data.forEach((schedule) => {
                        var sch = new Schedule(schedule);
                        sch.scheduleTimes = sch.scheduleTimes.map((scheduleTime) => new ScheduleTime(scheduleTime));
                        this.schedules.push(sch);
                    });
                }
                else if (this.device.id_group && this.device.id_group.value > 0) {
                    this.scheduleService.getAll('id_group==' + this.device.id_group.value.toString(), 'priority=ASC', undefined, 9999).subscribe((schedules) => {
                        this.schedules = [];
                        this.scheduleByGroup.value = TrileanValue.TRUE;
                        schedules.data.forEach((schedule) => {
                            var sch = new Schedule(schedule);
                            sch.scheduleTimes = sch.scheduleTimes.map((scheduleTime) => new ScheduleTime(scheduleTime));
                            this.schedules.push(sch);
                        });
                    });
                }
            });
        }
        else if (this.group && this.group.id) {
            this.scheduleService.getAll('id_group==' + this.group.id.toString(), 'priority=ASC', undefined, 9999).subscribe((schedules) => {
                this.schedules = [];
                this.scheduleByGroup.value = TrileanValue.TRUE;
                schedules.data.forEach((schedule) => {
                    var sch = new Schedule(schedule);
                    sch.scheduleTimes = sch.scheduleTimes.map((scheduleTime) => new ScheduleTime(scheduleTime));
                    this.schedules.push(sch);
                });
            });
        }
    }
    openDialogSchedule() {
        this.scheduleDialog = true;
        this.schedule.scheduleTimes.forEach((sT) => {
            this.sTimes.push(sT);
        });
        this.msgError = '';
    }
    closeDialogSchedule() {
        this.scheduleDialog = false;
    }
    editItem(item) {
        this.schedule = item.clone();
        this.openDialogSchedule();
    }
    getIntervalFormat(interval) {
        return interval.time;
    }
    showScheduleTime(event) {
        this.selectedScheduleTime = event.event.item;
        this.selectedIndex = this.schedule.scheduleTimes.findIndex((scheduleTime) => {
            return scheduleTime.weekday.value == this.selectedScheduleTime.weekday.value &&
                scheduleTime.start_time == this.selectedScheduleTime.start_time &&
                scheduleTime.end_time == this.selectedScheduleTime.end_time;
        });
        this.openDialog();
        event.nativeEvent.stopPropagation();
    }
    showTime(event) {
        this.selectedIndex = -1;
        if (this.dialog)
            return;
        let weekday = moment(event.date).day();
        this.selectedScheduleTime = new ScheduleTime({
            id: -1,
            id_schedule: this.schedule.id,
            weekday: weekday > 0 ? this.enumWeekday.find((wd) => wd.value == weekday) : 7,
            start_time: moment(event.hour + ':00', 'HH:mm').format('HH:mm'),
            end_time: moment(event.hour + ':00', 'HH:mm').add(1, 'hour').format('HH:mm')
        });
        this.openDialog();
    }
    openDialog() {
        this.dialog = true;
        this.msgError = '';
    }
    closeDialog() {
        this.dialog = false;
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], DeviceSchedule.prototype, "device", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], DeviceSchedule.prototype, "group", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DeviceSchedule.prototype, "canDelete", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DeviceSchedule.prototype, "canUpdate", void 0);
tslib_1.__decorate([
    Watch('device'),
    Watch('group')
], DeviceSchedule.prototype, "onDeviceChanged", null);
DeviceSchedule = tslib_1.__decorate([
    Component({
        components: {
            DataTable, MaterialMenuInfo, DatePicker, ColorPicker, TimePicker
        }
    })
], DeviceSchedule);
export default DeviceSchedule;
