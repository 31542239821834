import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import CompanyService from '@/services/CompanyService';
import JSPage from '@/classes/JSPage';
let CompaniesPage = class CompaniesPage extends JSPage {
    constructor() {
        super(...arguments);
        this.companyService = new CompanyService();
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'CIF', value: 'cif', align: 'start' },
            { text: 'Dispositivos', value: 'devices_stats', align: 'center' },
            { text: 'Usuarios', value: 'users_stats', align: 'center' },
            { text: 'Nº recepción SMS', value: 'labsmobile_sender', align: 'center' },
            { text: 'Estado', value: 'status.strValue', align: 'end' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'CIF', value: 'cif', content: '', toggle: true, type: 'text' },
            { text: 'Estado', value: 'status', content: '', toggle: true, type: 'enum', enum_name: 'Status' }
        ];
    }
    created() {
        super.created();
    }
    main() {
        if (!this.user.isAdmin()) {
            this.$router.back();
        }
    }
};
CompaniesPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], CompaniesPage);
export default CompaniesPage;
