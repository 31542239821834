import { render, staticRenderFns } from "./ColorPicker.html?vue&type=template&id=d1b777b0&scoped=true&"
import script from "./ColorPicker.ts?vue&type=script&lang=ts&"
export * from "./ColorPicker.ts?vue&type=script&lang=ts&"
import style0 from "./ColorPicker.scss?vue&type=style&index=0&id=d1b777b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1b777b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VColorPicker,VMenu,VTextField})
