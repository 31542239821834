import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
let MaterialStatsCard = class MaterialStatsCard extends Vue {
};
tslib_1.__decorate([
    Prop({ required: true })
], MaterialStatsCard.prototype, "icon", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "subIcon", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "subIconColor", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "subTextColor", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "subText", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialStatsCard.prototype, "smallValue", void 0);
MaterialStatsCard = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard
        },
        inheritAttrs: false,
        props: {
            ...MaterialCard.extend
        }
    })
], MaterialStatsCard);
export default MaterialStatsCard;
