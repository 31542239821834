import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import ConfigurationService from '@/services/ConfigurationService';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import JSPage from '@/classes/JSPage';
let ConfigurationPage = class ConfigurationPage extends JSPage {
    constructor() {
        super(...arguments);
        this.configurationService = new ConfigurationService();
        this.configuration = {};
    }
    created() {
        super.created();
    }
    main() {
        this.getConfiguration();
    }
    getConfiguration() {
        this.configurationService.get().subscribe((configuration) => {
            this.configuration = configuration;
        }, (error) => {
            this.$router.back();
        });
    }
    cancel() {
        this.$router.back();
    }
    update() {
        this.configurationService.update(this.configuration).subscribe((configuration) => {
            this.configuration = configuration;
        }, (error) => {
        });
    }
};
ConfigurationPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard
        }
    })
], ConfigurationPage);
export default ConfigurationPage;
