import { ForeignEntry } from './ForeignEntry';
export class Group {
    constructor(data) {
        this.id_company = new ForeignEntry();
        this.name = '';
        this.description = '';
        this.devices = [];
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
}
