import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import Assets from '@/classes/Assets';
let MaterialCard = class MaterialCard extends Vue {
    classes() {
        return 'v-card--material--has-heading : ' + this.hasHeading();
    }
    hasHeading() {
        return Boolean(this.$slots.heading || this.title || this.icon);
    }
    hasAltHeading() {
        return Boolean(this.$slots.heading || (this.title && this.icon));
    }
    input($event) {
        this.$emit('input', $event);
    }
    file($event) {
        this.$emit('file', $event);
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialCard.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialCard.prototype, "avatar", void 0);
tslib_1.__decorate([
    Prop({ default: Assets.getImage('default_admin.png') })
], MaterialCard.prototype, "defaultAvatar", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialCard.prototype, "uploadField", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialCard.prototype, "editable", void 0);
tslib_1.__decorate([
    Prop({ default: 'success' })
], MaterialCard.prototype, "color", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialCard.prototype, "icon", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MaterialCard.prototype, "image", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialCard.prototype, "text", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialCard.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialCard.prototype, "title_class", void 0);
MaterialCard = tslib_1.__decorate([
    Component({
        components: {
            MaterialAvatar
        }
    })
], MaterialCard);
export default MaterialCard;
