import { render, staticRenderFns } from "./CompaniesPage.html?vue&type=template&id=beec0af0&scoped=true&"
import script from "./CompaniesPage.ts?vue&type=script&lang=ts&"
export * from "./CompaniesPage.ts?vue&type=script&lang=ts&"
import style0 from "./CompaniesPage.scss?vue&type=style&index=0&id=beec0af0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beec0af0",
  null
  
)

export default component.exports