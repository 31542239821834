import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import DeviceSchedule from '@/components/DeviceSchedule/DeviceSchedule.vue';
import GroupService from '@/services/GroupService';
import { ForeignEntry } from '@/models/ForeignEntry';
import CompanyService from '@/services/CompanyService';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let GroupMainTab = class GroupMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.groupService = new GroupService();
        this.companyService = new CompanyService();
        this.companies = [];
    }
    created() {
        super.created();
    }
    main() {
        this.getCompanies();
    }
    getCompanies() {
        this.companyService.getAll(undefined, undefined, undefined, 9999).subscribe((companies) => {
            this.companies = [];
            companies.data.forEach((company) => this.companies.push(new ForeignEntry(company.id, company.name)));
            if (!this.group.id && this.companies.length > 0) {
                this.group.id_company = this.companies[0];
            }
        });
    }
    update() {
        let d = this.group;
        if (this.id_company > 0) {
            d.id_company = new ForeignEntry(this.id_company);
        }
        if (d) {
            if (!d.id) {
                this.groupService.add(d).subscribe((group) => {
                    this.$router.replace(group.id.toString());
                }, (error) => {
                });
            }
            else {
                this.groupService.update(d).subscribe((group) => {
                    this.$emit('update', group);
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], GroupMainTab.prototype, "group", void 0);
GroupMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField, DeviceSchedule
        }
    })
], GroupMainTab);
export default GroupMainTab;
