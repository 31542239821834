import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Assets from '@/classes/Assets';
let MaterialAvatar = class MaterialAvatar extends Vue {
    constructor() {
        super(...arguments);
        this.previousValue = '';
        this.isDragging = false;
        this.isHover = false;
        this.validExtensions = ['image/jpg', 'image/jpeg', 'image/png'];
        this.noFile = true;
        this.notFoundImage = false;
    }
    onValueChanged(newValue, oldValue) {
        this.notFoundImage = false;
        if (this.noFile) {
            this.previousValue = newValue;
        }
    }
    fileInput() {
        return this.$refs.fileInput;
    }
    getImageSrc() {
        return (this.value && this.value !== '' && !this.notFoundImage) ? this.value : this.defaultImage;
    }
    imageLoadError() {
        this.notFoundImage = true;
    }
    imagePath() {
        return this.value && this.value !== this.defaultImage;
    }
    remove() {
        if (!this.editable)
            return;
        if (this.value === this.previousValue) {
            this.onFileChanged('', undefined);
            this.previousValue = '';
        }
        else {
            this.onFileChanged(this.previousValue, undefined);
        }
    }
    inputTriggerClick() {
        if (!this.editable)
            return;
        this.fileInput().click();
    }
    onInputChanged() {
        if (!this.editable)
            return;
        if (this.fileInput().files && this.fileInput().files[0]) {
            var reader = new FileReader();
            var me = this;
            reader.onload = function (ev) {
                me.onFileChanged(ev.target.result, me.fileInput().files[0]);
            };
            reader.readAsDataURL(this.fileInput().files[0]);
        }
    }
    onDragOver(ev) {
        if (!this.editable)
            return;
        ev.preventDefault();
        this.isDragging = true;
    }
    onDragLeave(ev) {
        if (!this.editable)
            return;
        ev.preventDefault();
        this.isDragging = false;
    }
    onDrop(ev) {
        if (!this.editable)
            return;
        ev.preventDefault();
        let files = ev.dataTransfer.files;
        if (this.validExtensions.includes(files[0].type)) {
            this.fileInput().files = ev.dataTransfer.files;
            this.onInputChanged();
        }
        this.isDragging = false;
    }
    onFileChanged(data, file) {
        if (!this.editable)
            return;
        this.noFile = file === undefined;
        this.$emit('input', data);
        this.$emit('uploadFile', file);
    }
};
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialAvatar.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialAvatar.prototype, "editable", void 0);
tslib_1.__decorate([
    Prop({ default: '128px' })
], MaterialAvatar.prototype, "size", void 0);
tslib_1.__decorate([
    Prop({ default: Assets.getImage('default_admin.png') })
], MaterialAvatar.prototype, "defaultImage", void 0);
tslib_1.__decorate([
    Watch('value')
], MaterialAvatar.prototype, "onValueChanged", null);
MaterialAvatar = tslib_1.__decorate([
    Component
], MaterialAvatar);
export default MaterialAvatar;
