import BaseService from './BaseService';
import { Observable } from "rxjs";
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class RoleService {
    constructor() {
        this.path = 'roles';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    add(role) {
        CBlocker.showLoading();
        let content = role;
        return new Observable((observer) => {
            BaseService.post(this.path, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Rol Añadido');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(role) {
        CBlocker.showLoading();
        let content = role;
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + role.id, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('¡Actualizado!');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    delete(role) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.delete(this.path + '/' + role.id, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Rol Eliminado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums(name) {
        if (RoleService.enums.length == 0) {
            let content = {};
            if (name)
                content.name = name;
            return BaseService.get(this.path + '/enums', content);
        }
        else {
            return new Observable((observer) => {
                observer.next(RoleService.enums);
            });
        }
    }
}
RoleService.enums = new Array();
