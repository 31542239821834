import Base64 from './Base64';
import moment from 'moment';
export default class Helpers {
    static getNestedValue(obj, path, fallback) {
        const last = path.length - 1;
        if (last < 0)
            return obj === undefined ? fallback : obj;
        for (let i = 0; i < last; i++) {
            if (obj == null) {
                return fallback;
            }
            obj = obj[path[i]];
        }
        if (obj == null)
            return fallback;
        return obj[path[last]] === undefined ? fallback : obj[path[last]];
    }
    static getObjectValueByPath(obj, path, fallback) {
        if (obj == null || !path || typeof path !== 'string')
            return fallback;
        if (obj[path] !== undefined)
            return obj[path];
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, ''); // strip a leading dot
        return Helpers.getNestedValue(obj, path.split('.'), fallback);
    }
    static stringToBase64(string) {
        return Base64.encode(string);
    }
    static base64ToString(string) {
        return Base64.decode(string);
    }
    static toLocalDateTime(string) {
        return moment(string, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss');
    }
    static toLocalDateTimeShort(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD-MM-YY - HH:mm');
    }
    static toLocalDate(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY');
    }
    static toLocalTime(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('HH:mm');
    }
    static toLocalDateShort(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD-MM-YY');
    }
    static toTime(string) {
        return moment(string, 'HH:mm:ss').format('HH:mm');
    }
    static toPrice(number) {
        return (number / 100).toFixed(2) + '€';
    }
    static toPercentage(number) {
        return (number / 100).toFixed(2) + '%';
    }
}
