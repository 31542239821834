import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import { ForeignEntry } from '@/models/ForeignEntry';
import PermissionService from '@/services/PermissionService';
import GroupService from '@/services/GroupService';
import { GroupPermission } from '@/models/GroupPermission';
import DeviceService from '@/services/DeviceService';
import RoleService from '@/services/RoleService';
import JSPage from '@/classes/JSPage';
let RolePermissionsTab = class RolePermissionsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.roleService = new RoleService();
        this.permissionService = new PermissionService();
        this.groupService = new GroupService();
        this.deviceService = new DeviceService();
        this.enumPermissions = [];
        this.selectedPermissions = [];
        this.groups = [];
        this.devices = [];
        this.groupPermissions = [];
    }
    onRoleChanged() {
        this.groupPermissions = Object.assign([], this.role.permissions);
        if (this.groupPermissions.length === 0) {
            this.groupPermissions.push(new GroupPermission());
        }
        this.getGroupAndDevices();
    }
    remainingGroups(gp) {
        return this.groups.filter((group) => {
            return this.groupPermissions.filter((groupPermission) => {
                var value = group instanceof Object ? group.value : group;
                return groupPermission.groups.includes(value) && groupPermission !== gp;
            }).length == 0;
        });
    }
    remainingDevices(gp) {
        return this.devices.filter((device) => {
            return this.groupPermissions.filter((groupPermission) => {
                var value = device instanceof Object ? device.value : device;
                return groupPermission.devices.includes(value) && groupPermission !== gp;
            }).length == 0;
        });
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.onRoleChanged();
    }
    getGroupAndDevices() {
        if (this.role && this.role.id_company) {
            this.groupService.getAll('id_company==' + this.role.id_company.value, 'name=ASC', 1, 9999).subscribe((groups) => {
                this.groups = [];
                groups.data.forEach((group) => {
                    var g = new ForeignEntry(group.id, group.name);
                    this.groups.push(g);
                });
            });
            this.deviceService.getAll('id_company==' + this.role.id_company.value + ';id_group==NULL', 'name=ASC', 1, 9999).subscribe((devices) => {
                this.devices = [];
                devices.data.forEach((device) => {
                    var d = new ForeignEntry(device.id, device.name);
                    this.devices.push(d);
                });
            });
        }
    }
    getEnums() {
        this.permissionService.getEnums().subscribe((enums) => {
            this.enumPermissions = enums['Permission'];
        });
    }
    update() {
        let d = Object.assign({}, this.role);
        d.permissions = this.groupPermissions;
        this.roleService.update(d).subscribe((role) => {
            this.$emit('input', role);
        });
    }
    onRemovePermission(index) {
        this.groupPermissions.splice(index, 1);
        if (this.groupPermissions.length === 0) {
            this.groupPermissions.push(new GroupPermission());
        }
    }
    canAddPermission() {
        if (this.groupPermissions.length == 0) {
            return true;
        }
        var lastPermission = this.groupPermissions[this.groupPermissions.length - 1];
        return ((lastPermission.groups.length > 0 || lastPermission.devices.length > 0) && lastPermission.permissions.length > 0);
    }
    addPermission() {
        if (this.canAddPermission()) {
            this.groupPermissions.push(new GroupPermission());
        }
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], RolePermissionsTab.prototype, "role", void 0);
tslib_1.__decorate([
    Watch('role')
], RolePermissionsTab.prototype, "onRoleChanged", null);
RolePermissionsTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField
        }
    })
], RolePermissionsTab);
export default RolePermissionsTab;
