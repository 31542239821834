import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import MaterialUploader from '@/components/Base/MaterialUploader/MaterialUploader.vue';
import Helpers from '@/classes/Helpers';
import ResponseService from '@/services/ResponseService';
import { Device } from '@/models/Device';
import DeviceService from '@/services/DeviceService';
import JSPage from '@/classes/JSPage';
let CompanyAlertsTab = class CompanyAlertsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.responseService = new ResponseService();
        this.tableName = 'Alertas';
        this.helpers = Helpers;
        this.headers = [
            { text: 'Fecha', value: 'created_at', align: 'start', type: IDataTableHeaderType.DATETIME },
            { text: 'Dispositivo', value: 'id_device.strValue', maxWidth: 300 },
            { text: 'Mensaje', value: 'message', align: 'start' }
        ];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setFilters();
    }
    setFilters() {
        this.filters = [
            { text: 'Fecha', value: 'created_at', content: undefined, toggle: true, type: 'date' },
            { text: 'Dispositivo', value: 'id_device', content: '', toggle: true, type: 'foreign',
                foreignService: new DeviceService(), field: 'name', order: 'name=ASC', class: Device,
                filter: 'id_company==' + this.company.id.toString() },
        ];
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], CompanyAlertsTab.prototype, "company", void 0);
CompanyAlertsTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable,
            MaterialUploader
        }
    })
], CompanyAlertsTab);
export default CompanyAlertsTab;
