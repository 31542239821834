import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
let ColorPicker = class ColorPicker extends Vue {
    constructor() {
        super(...arguments);
        this.mask = '!#XXXXXX';
        this.color = this.value;
        this.showMenu = false;
    }
    onValueChanged(newValue) {
        this.color = newValue;
    }
    onColorChanged(newValue) {
        this.$emit('input', this.color);
    }
};
tslib_1.__decorate([
    Prop({ default: '#1976D2' })
], ColorPicker.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: 'Color picker' })
], ColorPicker.prototype, "label", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], ColorPicker.prototype, "disabled", void 0);
tslib_1.__decorate([
    Watch('value')
], ColorPicker.prototype, "onValueChanged", null);
tslib_1.__decorate([
    Watch('color')
], ColorPicker.prototype, "onColorChanged", null);
ColorPicker = tslib_1.__decorate([
    Component({
        directives: {
            mask
        }
    })
], ColorPicker);
export default ColorPicker;
