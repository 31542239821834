import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import MaterialUploader from '@/components/Base/MaterialUploader/MaterialUploader.vue';
import DeviceService from '@/services/DeviceService';
import ResponseService from '@/services/ResponseService';
import Helpers from '@/classes/Helpers';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import { CBlocker } from '@/components/Blocker/CBlocker';
import { Device } from '@/models/Device';
import { Permission } from '@/models/Permission';
import JSPage from '@/classes/JSPage';
let DeviceOpsTab = class DeviceOpsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.device = new Device();
        this.deviceService = new DeviceService();
        this.responseService = new ResponseService();
        this.tableName = 'Respuestas recibidas';
        this.enumTestMode = [];
        this.helpers = Helpers;
        this.Permission = Permission;
        this.key = 1;
        this.headers = [
            { text: 'Fecha', value: 'created_at', align: 'start', type: IDataTableHeaderType.DATETIME },
            { text: 'Mensaje', value: 'message', align: 'start' }
        ];
    }
    onDeviceChanged() {
        console.log('go');
        this.device = this.value;
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.device = this.value;
    }
    getEnums() {
        this.deviceService.getEnums().subscribe((enums) => {
            this.enumTestMode = enums['TestMode'];
        });
    }
    openRelay() {
        CBlocker.showConfirm('¿Desea abrir el relé del dispositivo seleccionado?', () => {
            this.deviceService.openRelay(this.device.id).subscribe(() => { });
        });
    }
    closeRelay() {
        CBlocker.showConfirm('¿Desea cerrar el relé del dispositivo seleccionado?', () => {
            this.deviceService.closeRelay(this.device.id).subscribe(() => { });
        });
    }
    getStatus() {
        CBlocker.showConfirm('¿Desea obtener el estado del dispositivo seleccionado?', () => {
            this.deviceService.getStatus(this.device.id).subscribe(() => { });
        });
    }
    reboot() {
        CBlocker.showConfirm('¿Desea reiniciar el dispositivo seleccionado?', () => {
            this.deviceService.reboot(this.device.id).subscribe(() => { });
        });
    }
    setup() {
        CBlocker.showConfirm('¿Desea configurar el dispositivo seleccionado?', () => {
            this.deviceService.setup(this.device.id).subscribe(() => { });
        });
    }
    onTestModeChanged(event) {
        var inTestMode = 0;
        if (event) {
            inTestMode = 1;
        }
        var d = Object.assign({}, this.device);
        d.in_test_mode = this.enumTestMode[inTestMode];
        this.deviceService.update(d, false).subscribe((device) => {
            this.$emit('input', new Device(device));
        });
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], DeviceOpsTab.prototype, "value", void 0);
tslib_1.__decorate([
    Watch('value')
], DeviceOpsTab.prototype, "onDeviceChanged", null);
DeviceOpsTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable,
            MaterialUploader,
        }
    })
], DeviceOpsTab);
export default DeviceOpsTab;
