import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import RoleMainTab from './Tabs/RoleMainTab/RoleMainTab.vue';
import RolePermissionsTab from './Tabs/RolePermissionsTab/RolePermissionsTab.vue';
import RoleService from '@/services/RoleService';
import JSPage from '@/classes/JSPage';
let RolesPage = class RolesPage extends JSPage {
    constructor() {
        super(...arguments);
        this.roleService = new RoleService();
        this.role = {};
        this.tabs = new Array();
        this.tab = '';
    }
    onRoleChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
    }
    main() {
        if (this.user.isUser()) {
            this.$router.back();
        }
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Rol', icon: 'mdi-shield-account', href: 'main-tab' });
        if (this.role.id) {
            this.tabs.push({ title: 'Permisos', icon: 'mdi-shield-key-outline', href: 'permissions-tab' });
        }
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getRole();
        }
        else {
            if (id !== 'new') {
                this.cancel();
            }
        }
    }
    getRole() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.roleService.get(id).subscribe((role) => {
                this.role = role;
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.role.id) {
            this.roleService.add(this.role).subscribe((role) => {
                this.role = role;
            }, (error) => { });
        }
        else {
            this.roleService.update(this.role).subscribe((role) => {
                this.role = role;
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onRoleUpdate(event) {
        this.role = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('role')
], RolesPage.prototype, "onRoleChanged", null);
RolesPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, Tabs,
            RoleMainTab, RolePermissionsTab
        }
    })
], RolesPage);
export default RolesPage;
