import { EnumEntry } from './EnumEntry';
import Assets from "@/classes/Assets";
import { ForeignEntry } from "./ForeignEntry";
import { Group } from "./Group";
import { Device } from "./Device";
export class User {
    constructor(data) {
        this.name = '';
        this.email = '';
        this.type = new EnumEntry();
        this.id_company = new ForeignEntry();
        this.id_role = new ForeignEntry();
        this.status = new EnumEntry();
        this.image = '';
        this._notFoundImage = false;
        this._DEFAULT_IMAGE_ADMIN = Assets.getImage('default_admin.png');
        this._DEFAULT_IMAGE_USER = Assets.getImage('default_user.png');
        this.id = 0;
        if (data) {
            Object.assign(this, data);
            this.ts_expiration = data.ts_expiration ? data.ts_expiration : new Date();
        }
    }
    hasPermission(permission, entity) {
        if (!this.isUser()) {
            return true;
        }
        if (entity.id == 0) {
            return false;
        }
        return this.permissions.filter((perm) => {
            var id_group = 0;
            var id_device = 0;
            console.log(entity instanceof Device);
            console.log(entity instanceof Group);
            if (entity instanceof Device) {
                if (entity.id_group && entity.id_group.value > 0) {
                    id_group = entity.id_group.value;
                }
                else {
                    id_device = entity.id;
                }
            }
            else if (entity instanceof Group) {
                id_group = entity.id;
            }
            if (id_group > 0) {
                return perm.groups.includes(id_group) && perm.permissions.includes(permission);
            }
            else if (id_device > 0) {
                return perm.devices.includes(id_device) && perm.permissions.includes(permission);
            }
        }).length > 0;
    }
    getImageSrc() {
        return (this.image && this.image !== '' && !this._notFoundImage) ?
            this.image :
            this.getDefaultImage();
    }
    getDefaultImage() {
        return this.isAdmin() || this.isCompany() ?
            this._DEFAULT_IMAGE_ADMIN :
            this._DEFAULT_IMAGE_USER;
    }
    catchImageError() {
        this._notFoundImage = true;
    }
    getTypeStr() {
        switch (this.type.value) {
            case User.TYPE_ADMINISTRATOR:
                return 'Administrador';
            case User.TYPE_COMPANY:
                return 'Administrador de empresa';
            case User.TYPE_USER:
                return 'Técnico';
            default:
                return '';
        }
    }
    getStatusStr() {
        return !this.status ? '' : this.status.strValue;
    }
    toNormalize() {
        let user = Object.assign({}, this);
        if (user.type instanceof Object) {
            user.type = user.type.value;
        }
        if (user.status instanceof Object) {
            user.status = user.status.value;
        }
        if (user.type != User.TYPE_USER) {
            delete (user.id_role);
        }
        delete (user.id);
        delete (user.token);
        delete (user.ts_expiration);
        delete (user.last_access);
        delete (user.permissions);
        delete (user._notFoundImage);
        delete (user._DEFAULT_IMAGE_ADMIN);
        delete (user._DEFAULT_IMAGE_USER);
        if (user.image_FILE) {
            user.image = user.image_FILE;
        }
        delete (user.image_FILE);
        return user;
    }
    isAdmin() {
        return this.type && this.type.value === User.TYPE_ADMINISTRATOR;
    }
    isUser() {
        return this.type && this.type.value === User.TYPE_USER;
    }
    isCompany() {
        return this.type && this.type.value === User.TYPE_COMPANY;
    }
    isLogged() {
        return this.id > 0;
    }
}
User.TYPE_ADMINISTRATOR = 99;
User.TYPE_COMPANY = 100;
User.TYPE_USER = 101;
User.STATUS_ACTIVE = 1;
User.STATUS_INACTIVE = 2;
