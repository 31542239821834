import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import DeviceService from '@/services/DeviceService';
import CompanyService from '@/services/CompanyService';
import { Company } from '@/models/Company';
import { Device } from '@/models/Device';
import JSPage from '@/classes/JSPage';
let GroupDevicesTab = class GroupDevicesTab extends JSPage {
    constructor() {
        super(...arguments);
        this.deviceService = new DeviceService();
        this.enumTestMode = [];
        this.headers = [];
        this.filters = [];
        this.Device = Device;
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' }
        ];
        this.headers.push({ text: 'Nº SIM', value: 'sim', align: 'start' }, { text: 'Location', value: 'location', align: 'start' });
        if (this.user.isAdmin()) {
            this.headers.push({ text: 'Empresa', value: 'id_company.strValue', align: 'start' });
        }
        this.headers.push({ text: 'Estado Relé', value: 'state_html', type: IDataTableHeaderType.HTML, sortable: false }, { text: 'Alimentación', value: 'isOn_html', type: IDataTableHeaderType.HTML, sortable: false }, { text: 'Test', value: 'in_test_mode', align: 'center', sortable: false });
        this.headers.push({ text: '', value: 'actions', align: 'end', sortable: false });
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
        this.filters.push({ text: 'Nº SIM', value: 'sim', content: '', toggle: true, type: 'text' }, { text: 'Location', value: 'location', content: '', toggle: true, type: 'text' });
        if (this.user.isAdmin()) {
            this.filters.push({ text: 'Empresa', value: 'id_company', content: '', toggle: true, type: 'foreign',
                foreignService: new CompanyService(), field: 'name', order: 'name=ASC', class: Company });
        }
    }
    getEnums() {
        this.deviceService.getEnums().subscribe((enums) => {
            this.enumTestMode = enums['TestMode'];
        });
    }
    onTestModeChanged(event, device) {
        var inTestMode = 0;
        if (event) {
            inTestMode = 1;
        }
        device.in_test_mode = this.enumTestMode[inTestMode];
        var d = new Device(device);
        d.in_test_mode = this.enumTestMode[inTestMode];
        delete (d.isSelected);
        this.deviceService.update(d, false).subscribe((device) => {
        });
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], GroupDevicesTab.prototype, "group", void 0);
GroupDevicesTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], GroupDevicesTab);
export default GroupDevicesTab;
