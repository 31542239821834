import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import GroupService from '@/services/GroupService';
import JSPage from '@/classes/JSPage';
let CompanyGroupsTab = class CompanyGroupsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.groupService = new GroupService();
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' }
        ];
        this.headers.push({ text: 'Dispositivos', value: 'devices.length', align: 'center', sortable: false }, { text: 'Descripción', value: 'description', align: 'start' }, { text: '', value: 'actions', align: 'end', sortable: false });
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], CompanyGroupsTab.prototype, "company", void 0);
CompanyGroupsTab = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], CompanyGroupsTab);
export default CompanyGroupsTab;
