import BaseService from './BaseService';
import { Observable } from "rxjs";
import { CBlocker } from '@/components/Blocker/CBlocker';
import { Device } from '@/models/Device';
export default class DeviceService {
    constructor() {
        this.path = 'devices';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    add(device) {
        CBlocker.showLoading();
        let content = new Device(device).toNormalize();
        return new Observable((observer) => {
            BaseService.post(this.path, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Dispositivo añadido');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(device, showLoading = true) {
        if (showLoading) {
            CBlocker.showLoading();
        }
        let content = new Device(device).toNormalize();
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + device.id, content, undefined, false).subscribe((success) => {
                observer.next(success);
                if (showLoading) {
                    CBlocker.showSuccess('¡Actualizado!');
                }
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    delete(device) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.delete(this.path + '/' + device.id, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Dispositivo eliminado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums(name) {
        if (DeviceService.enums.length == 0) {
            let content = {};
            if (name)
                content.name = name;
            return BaseService.get(this.path + '/enums', content);
        }
        else {
            return new Observable((observer) => {
                observer.next(DeviceService.enums);
            });
        }
    }
    openRelay(id) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path + '/' + id.toString() + '/switchOff', null, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Comando enviado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    closeRelay(id) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path + '/' + id.toString() + '/switchOn', null, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Comando enviado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getStatus(id) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path + '/' + id.toString() + '/status', null, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Comando enviado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    reboot(id) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path + '/' + id.toString() + '/reboot', null, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Comando enviado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    setup(id) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path + '/' + id.toString() + '/configure', null, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Comando enviado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
}
DeviceService.enums = new Array();
