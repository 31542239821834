import * as tslib_1 from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import Topbar from "@/components/Topbar/Topbar.vue";
import Blocker from "@/components/Blocker/Blocker.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import LightBox from '@/components/Base/LightBox/LightBox.vue';
import Globals from './classes/Globals';
let App = class App extends Vue {
    onRouteChanged(newValue, oldValue) {
        Globals.setPageFound(true, true);
    }
    created() {
        Globals.init();
    }
};
tslib_1.__decorate([
    Watch('$route')
], App.prototype, "onRouteChanged", null);
App = tslib_1.__decorate([
    Component({
        components: {
            Topbar,
            Blocker,
            Sidebar,
            LightBox
        }
    })
], App);
export default App;
