import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
let DatePicker = class DatePicker extends Vue {
    constructor() {
        super(...arguments);
        this.valueInput = this.value !== undefined ? moment(this.value).format('DD/MM/YYYY') : "";
        this.valuePicker = this.allowRange ?
            [moment(this.value).format('YYYY-MM-DD'), moment(this.endValue).format('YYYY-MM-DD')] :
            (this.value !== undefined ? moment(this.value).format('YYYY-MM-DD') : '');
        this.showMenu = false;
    }
    onValueChanged(newValue) {
        this.valueInput = this.value !== undefined ? moment(this.value).format('DD/MM/YYYY') : '';
        this.valuePicker = this.value !== undefined ? moment(this.value).format('YYYY-MM-DD') : undefined;
    }
    onClear() {
        this.$emit('input', undefined);
    }
    onChanged() {
        this.$emit('input', this.valuePicker);
    }
};
tslib_1.__decorate([
    Prop()
], DatePicker.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: moment().format() })
], DatePicker.prototype, "endValue", void 0);
tslib_1.__decorate([
    Prop({ default: 'Fecha' })
], DatePicker.prototype, "caption", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DatePicker.prototype, "allowRange", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DatePicker.prototype, "outlined", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DatePicker.prototype, "dense", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DatePicker.prototype, "disabled", void 0);
tslib_1.__decorate([
    Watch('value')
], DatePicker.prototype, "onValueChanged", null);
DatePicker = tslib_1.__decorate([
    Component
], DatePicker);
export default DatePicker;
