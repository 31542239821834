export class EnumEntry {
    constructor(value, strValue, enumName) {
        this.value = value ? value : -1;
        this.strValue = strValue ? strValue : '';
        this.enumName = enumName;
    }
    static createFrom(data) {
        return new EnumEntry(data.value, data.strValue, data.enumName);
    }
}
