import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MenuItem from "@/components/Base/MenuItem/MenuItem.vue";
let MenuItemSubGroup = class MenuItemSubGroup extends Vue {
};
tslib_1.__decorate([
    Prop({ default: undefined })
], MenuItemSubGroup.prototype, "avatar", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MenuItemSubGroup.prototype, "group", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MenuItemSubGroup.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: [] })
], MenuItemSubGroup.prototype, "children", void 0);
MenuItemSubGroup = tslib_1.__decorate([
    Component({
        components: {
            MenuItem,
        }
    })
], MenuItemSubGroup);
export default MenuItemSubGroup;
