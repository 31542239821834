import BaseService from './BaseService';
import { Observable } from 'rxjs';
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class ConfigurationService {
    constructor() {
        this.path = 'configuration';
    }
    get() {
        return BaseService.get(this.path);
    }
    update(configuration) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.put(this.path, configuration, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('¡Configuración guardada!');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums() {
        return new Observable((observer) => {
            if (ConfigurationService.enums.length == 0) {
                BaseService.get(this.path + '/enums').subscribe((enums) => {
                    ConfigurationService.enums = enums;
                    observer.next(ConfigurationService.enums);
                });
            }
            else {
                observer.next(ConfigurationService.enums);
            }
        });
    }
}
ConfigurationService.enums = new Array();
