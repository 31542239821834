import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
// import Notifier from '@/classes/Notifier';
// import NotificationService from '@/services/NotificationService';
import Sentinel from '@/classes/Sentinel';
import Globals from '@/classes/Globals';
let TopBar = class TopBar extends Vue {
    constructor() {
        super(...arguments);
        // private notificationService : NotificationService = new NotificationService();
        this.user = Sentinel.getUser(true, '/');
        this.userName = '';
        // private unreadNotifications:Number=Notifier.getUnreadNotifitions();
        this.foundPage = true;
    }
    // @Watch( 'user' )
    // onTopBarUserChanged() {
    //     Notifier.checkUnreadNotifications(this.user.id);
    // }
    created() {
        Globals.watch(this, (globals) => this.foundPage = globals.isPageFound());
        Sentinel.watchUser(this, (user) => this.user = user);
        // Subscription created
        // Notifier.watchUnreadNotifications().subscribe(unreadNumber=>this.unreadNotifications = unreadNumber);   
    }
    logout() {
        Sentinel.removeUser('/');
    }
};
TopBar = tslib_1.__decorate([
    Component({
        computed: mapState([
            'sidebarState'
        ]),
        methods: mapMutations({
            setSidebarState: 'setSidebarState'
        })
    })
], TopBar);
export default TopBar;
