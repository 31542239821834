import { EnumEntry } from './EnumEntry';
export class Permission {
    constructor(data) {
        this.permission = new EnumEntry();
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    static getAvailablePermissions() {
        return [
            Permission.PERMISSION_DEVICE_ON,
            Permission.PERMISSION_DEVICE_OFF,
            Permission.PERMISSION_DEVICE_STATUS,
            Permission.PERMISSION_DEVICE_RESET,
            Permission.PERMISSION_DEVICE_CONFIGURE,
            Permission.PERMISSION_DEVICE_VIEW,
            Permission.PERMISSION_DEVICE_EDIT_SCHEDULE
        ];
    }
}
Permission.PERMISSION_DEVICE_ON = 1;
Permission.PERMISSION_DEVICE_OFF = 2;
Permission.PERMISSION_DEVICE_STATUS = 4;
Permission.PERMISSION_DEVICE_RESET = 8;
Permission.PERMISSION_DEVICE_CONFIGURE = 16;
Permission.PERMISSION_DEVICE_VIEW = 32;
Permission.PERMISSION_DEVICE_EDIT_SCHEDULE = 64;
