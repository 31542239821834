var LightBoxSubscription_1;
import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Subject } from "rxjs";
let LightBoxSubscription = LightBoxSubscription_1 = class LightBoxSubscription extends Vue {
    static watch(subscriber, observer) {
        LightBoxSubscription_1.dataSubject.asObservable().subscribe(observer);
    }
    static show(url, actualIndex = 0) {
        LightBoxSubscription_1.dataSubject.next({ urls: url, show: true, actualIndex: actualIndex });
    }
    static close() {
        LightBoxSubscription_1.dataSubject.next({ show: false });
    }
};
LightBoxSubscription.dataSubject = new Subject();
LightBoxSubscription = LightBoxSubscription_1 = tslib_1.__decorate([
    Component
], LightBoxSubscription);
export default LightBoxSubscription;
