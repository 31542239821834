import { Subject } from "rxjs";
export default class Globals {
    static watch(subscriber, observer) {
        const subscription = this.dataSubject.asObservable().subscribe(observer);
    }
    static init() {
        Globals.set('page-not-found', false);
    }
    static set(valueName, value) {
        Globals.data.set(valueName, value);
        this.dataSubject.next(this);
    }
    static get(valueName) {
        if (Globals.data.has(valueName)) {
            return Globals.data.get(valueName);
        }
        return false;
    }
    static setPageFound(value = true, flag = false) {
        if (flag) {
            Globals.set('page-not-found', !value);
            Globals.set('page-not-found__f', value);
        }
        else {
            if (Globals.get('page-not-found__f')) {
                Globals.set('page-not-found', !value);
            }
            Globals.set('page-not-found__f', value);
        }
    }
    static isPageFound() {
        return !Globals.get('page-not-found');
    }
}
Globals.data = new Map();
Globals.dataSubject = new Subject();
Globals.init();
