import { render, staticRenderFns } from "./CompanyUsersTab.html?vue&type=template&id=893f4e5e&scoped=true&"
import script from "./CompanyUsersTab.ts?vue&type=script&lang=ts&"
export * from "./CompanyUsersTab.ts?vue&type=script&lang=ts&"
import style0 from "./CompanyUsersTab.scss?vue&type=style&index=0&id=893f4e5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "893f4e5e",
  null
  
)

export default component.exports