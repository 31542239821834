import { EnumEntry } from './EnumEntry';
import { ForeignEntry } from "./ForeignEntry";
export class Company {
    constructor(data) {
        this.name = '';
        this.cif = '';
        this.email = '';
        this.address = '';
        this.postal_code = '';
        this.city = '';
        this.province = new ForeignEntry();
        this.phone = '';
        this.contact = '';
        this.labsmobile_user_name = '';
        this.labsmobile_sender = '';
        this.status = new EnumEntry();
        this.devices_stats = '';
        this.users_stats = '';
        this.groups_stats = '';
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    getStatusStr() {
        return !this.status ? '' : this.status.strValue;
    }
    toNormalize() {
        let company = Object.assign({}, this);
        delete (company.devices_stats);
        delete (company.users_stats);
        delete (company.groups_stats);
        return company;
    }
}
