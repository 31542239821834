import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let MenuItem = class MenuItem extends Vue {
};
tslib_1.__decorate([
    Prop({ default: {} })
], MenuItem.prototype, "item", void 0);
tslib_1.__decorate([
    Prop({ default: undefined })
], MenuItem.prototype, "role", void 0);
MenuItem = tslib_1.__decorate([
    Component
], MenuItem);
export default MenuItem;
