var CompanyUsersTab_1;
import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import UserService from '@/services/UserService';
import { User } from '@/models/User';
import JSPage from '@/classes/JSPage';
let CompanyUsersTab = CompanyUsersTab_1 = class CompanyUsersTab extends JSPage {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
    }
    created() {
        super.created();
    }
    main() {
        if (this.user.isUser()) {
            this.$router.back();
        }
        CompanyUsersTab_1.userTypeConfig[0].url = '/companies/' + this.company.id.toString() + '/admins';
        CompanyUsersTab_1.userTypeConfig[1].url = '/companies/' + this.company.id.toString() + '/users';
    }
    getUserType() {
        return CompanyUsersTab_1.userTypeConfig.filter(elem => elem.type === this.userType)[0];
    }
};
CompanyUsersTab.userTypeConfig = [
    {
        type: User.TYPE_COMPANY,
        title: 'Administradores',
        color: 'company_admin',
        url: '',
        headers: [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Email', value: 'email' },
            { text: 'Estado', value: 'status.strValue', align: 'end' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ],
        filters: [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Email', value: 'email', content: '', toggle: true, type: 'text' },
        ]
    },
    {
        type: User.TYPE_USER,
        title: 'Técnicos',
        color: 'user',
        url: 'users',
        headers: [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Email', value: 'email' },
            { text: 'Estado', value: 'status.strValue', align: 'end' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ],
        filters: [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Email', value: 'email', content: '', toggle: true, type: 'text' }
        ]
    }
];
tslib_1.__decorate([
    Prop({ default: {} })
], CompanyUsersTab.prototype, "company", void 0);
tslib_1.__decorate([
    Prop({ default: User.TYPE_USER })
], CompanyUsersTab.prototype, "userType", void 0);
CompanyUsersTab = CompanyUsersTab_1 = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], CompanyUsersTab);
export default CompanyUsersTab;
