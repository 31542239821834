import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
let TimePicker = class TimePicker extends Vue {
    constructor() {
        super(...arguments);
        this.time = this.value;
        this.msgError = '';
    }
    onValueChanged(newValue) {
        this.time = newValue;
    }
    onColorChanged(newValue) {
        if (this.validate(newValue)) {
            this.msgError = '';
            this.$emit('input', this.time);
        }
        else {
            this.msgError = 'La hora introducida está fuera de intervalo';
        }
    }
    validate(time) {
        var success = true;
        var parts = time.split(':');
        if (this.min != '') {
            this.min.split(':').forEach((part, index) => {
                var actualValue = parseInt(parts[index]);
                var shortestValue = parseInt(part);
                success = success && actualValue >= shortestValue;
            });
        }
        if (this.max != '') {
            this.max.split(':').forEach((part, index) => {
                var actualValue = parseInt(parts[index]);
                var greatestValue = parseInt(part);
                success = success && actualValue <= greatestValue;
            });
        }
        return success;
    }
};
tslib_1.__decorate([
    Prop({ default: '00:00' })
], TimePicker.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: 'Time picker' })
], TimePicker.prototype, "label", void 0);
tslib_1.__decorate([
    Prop({ default: 'HH:mm' })
], TimePicker.prototype, "format", void 0);
tslib_1.__decorate([
    Prop({ default: 1 })
], TimePicker.prototype, "minuteInterval", void 0);
tslib_1.__decorate([
    Prop({ default: '00:00' })
], TimePicker.prototype, "min", void 0);
tslib_1.__decorate([
    Prop({ default: '23:59' })
], TimePicker.prototype, "max", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], TimePicker.prototype, "disabled", void 0);
tslib_1.__decorate([
    Watch('value')
], TimePicker.prototype, "onValueChanged", null);
tslib_1.__decorate([
    Watch('time')
], TimePicker.prototype, "onColorChanged", null);
TimePicker = tslib_1.__decorate([
    Component({
        components: {
            VueTimepicker
        }
    })
], TimePicker);
export default TimePicker;
