export var IDataTableHeaderType;
(function (IDataTableHeaderType) {
    IDataTableHeaderType[IDataTableHeaderType["TEXT"] = 1] = "TEXT";
    IDataTableHeaderType[IDataTableHeaderType["IMAGE"] = 2] = "IMAGE";
    IDataTableHeaderType[IDataTableHeaderType["DATETIME"] = 3] = "DATETIME";
    IDataTableHeaderType[IDataTableHeaderType["PRICE"] = 4] = "PRICE";
    IDataTableHeaderType[IDataTableHeaderType["TIME"] = 5] = "TIME";
    IDataTableHeaderType[IDataTableHeaderType["DATE"] = 6] = "DATE";
    IDataTableHeaderType[IDataTableHeaderType["PERCENTAGE"] = 7] = "PERCENTAGE";
    IDataTableHeaderType[IDataTableHeaderType["HTML"] = 8] = "HTML";
})(IDataTableHeaderType || (IDataTableHeaderType = {}));
