import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LightBox from '../LightBox/LightBox';
let MaterialUploader = class MaterialUploader extends Vue {
    constructor() {
        super(...arguments);
        this.imageSrc = "";
        this.isDragging = false;
        this.isHover = false;
        this.fullscreen = false;
        this.DEFAULT_IMAGE = require('@/assets/images/default.jpg');
    }
    onSrcChange() {
        if (!this.src || this.src === '') {
            this.imageSrc = this.DEFAULT_IMAGE;
        }
        else {
            this.imageSrc = this.src;
        }
    }
    showFull() {
        LightBox.show(this.imageSrc);
    }
    fileInput() {
        return this.$refs.fileInput;
    }
    getImageSrc() {
        if (this.imageSrc instanceof File) {
            return '';
        }
        return this.imageSrc;
    }
    created() {
        if (!this.src || this.src === '') {
            this.imageSrc = this.DEFAULT_IMAGE;
        }
        else {
            this.imageSrc = this.src;
        }
    }
    mounted() {
        if (!this.src || this.src === '') {
            this.imageSrc = this.DEFAULT_IMAGE;
        }
        else {
            this.imageSrc = this.src;
        }
    }
    imagePath() {
        return this.imageSrc !== this.DEFAULT_IMAGE ? this.imageSrc : '';
    }
    remove() {
        this.fileInput().value = '';
        this.onFileChanged('', undefined);
        this.imageSrc = this.DEFAULT_IMAGE;
    }
    inputTriggerClick() {
        this.fileInput().click();
    }
    onInputChanged() {
        if (this.fileInput().files && this.fileInput().files[0]) {
            var reader = new FileReader();
            var me = this;
            reader.onload = function (ev) {
                me.imageSrc = ev.target.result.toString();
                me.onFileChanged(ev.target.result, me.fileInput().files[0]);
            };
            reader.readAsDataURL(this.fileInput().files[0]);
        }
    }
    onFileChanged(data, file) {
        this.$emit('input', data);
        this.$emit('uploadFile', file);
    }
    onDragOver(ev) {
        ev.preventDefault();
        this.isDragging = true;
    }
    onDragLeave(ev) {
        ev.preventDefault();
        this.isDragging = false;
    }
    onDrop(ev) {
        ev.preventDefault();
        this.fileInput().files = ev.dataTransfer.files;
        this.isDragging = false;
        this.onInputChanged();
    }
};
tslib_1.__decorate([
    Prop({ required: true })
], MaterialUploader.prototype, "src", void 0);
tslib_1.__decorate([
    Prop({ required: true })
], MaterialUploader.prototype, "field", void 0);
tslib_1.__decorate([
    Prop({ required: true })
], MaterialUploader.prototype, "text", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialUploader.prototype, "caption", void 0);
tslib_1.__decorate([
    Prop({ default: 'green' })
], MaterialUploader.prototype, "captionColor", void 0);
tslib_1.__decorate([
    Prop({ default: 0 })
], MaterialUploader.prototype, "aspectRatio", void 0);
tslib_1.__decorate([
    Watch('src')
], MaterialUploader.prototype, "onSrcChange", null);
MaterialUploader = tslib_1.__decorate([
    Component
], MaterialUploader);
export default MaterialUploader;
