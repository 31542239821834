export default class Assets {
    static getImage(image) {
        return Assets._notFoundImage ?
            require('@/assets/images/' + Assets._DEFAULT_IMAGE) :
            require('@/assets/images/' + image);
    }
    catchImageError() {
        Assets._notFoundImage = true;
    }
}
Assets._notFoundImage = false;
Assets._DEFAULT_IMAGE = Assets.getImage('default.jpg');
