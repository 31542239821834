import * as tslib_1 from "tslib";
import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Vue } from 'vue-property-decorator';
import { User } from '@/models/User';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
let UserModule = class UserModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.user = new User();
    }
    get User() {
        return this.user;
    }
    setUser(payload) {
        if (!payload.user) {
            this.user = new User();
            Vue.$cookies.remove('user');
        }
        else {
            this.user = new User(payload.user);
            if (payload.updateTS) {
                Vue.$cookies.set('user', payload.user, new Date(payload.user.ts_expiration.toString()));
            }
            else {
                var cookiesUser = Vue.$cookies.get('user');
                payload.user.token = cookiesUser.token;
                payload.user.ts_expiration = cookiesUser.ts_expiration;
                var actualTS = payload.user.ts_expiration.toString();
                Vue.$cookies.set('user', payload.user, new Date(actualTS));
            }
        }
    }
};
tslib_1.__decorate([
    Mutation
], UserModule.prototype, "setUser", null);
UserModule = tslib_1.__decorate([
    Module({ namespaced: true, name: 'user' })
], UserModule);
export default UserModule;
