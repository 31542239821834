import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import { ForeignEntry } from '@/models/ForeignEntry';
import CompanyService from '@/services/CompanyService';
import Router from '@/classes/Router';
import RoleService from '@/services/RoleService';
import JSPage from '@/classes/JSPage';
let RoleMainTab = class RoleMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.roleService = new RoleService();
        this.companyService = new CompanyService();
        this.companies = [];
    }
    onCompanyChanged() {
        this.$emit('input', this.role);
    }
    created() {
        super.created();
    }
    main() {
        this.getCompanies();
    }
    getCompanies() {
        this.companyService.getAll(undefined, 'name=ASC', undefined, 9999).subscribe((companies) => {
            this.companies = [];
            companies.data.forEach((company) => this.companies.push(new ForeignEntry(company.id, company.name)));
            if (!this.role.id && this.companies.length > 0) {
                this.role.id_company = this.companies[0];
            }
        });
    }
    update() {
        let d = this.role;
        if (this.id_company > 0) {
            d.id_company = new ForeignEntry(this.id_company);
        }
        if (d) {
            if (!d.id) {
                this.roleService.add(d).subscribe((role) => {
                    this.$router.replace(role.id.toString());
                }, (error) => {
                });
            }
            else {
                this.roleService.update(d).subscribe((role) => {
                    this.$emit('input', role);
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: {} })
], RoleMainTab.prototype, "role", void 0);
tslib_1.__decorate([
    Watch('role.id_company')
], RoleMainTab.prototype, "onCompanyChanged", null);
RoleMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField
        }
    })
], RoleMainTab);
export default RoleMainTab;
