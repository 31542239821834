import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from "@/pages/NotFound/NotFoundPage.vue";
import LoginPage from "@/pages/Login/LoginPage.vue";
import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";
import ConfigurationPage from '@/pages/Configuration/ConfigurationPage.vue';
import UsersPage from '@/pages/Users/UsersPage.vue';
import UserPage from '@/pages/Users/UserPage/UserPage.vue';
import ProfilePage from "@/pages/Profile/ProfilePage.vue";
import RolesPage from '@/pages/Role/RolesPage.vue';
import RolePage from '@/pages/Role/RolePage/RolePage.vue';
import { User } from '@/models/User';
import GroupsPage from '@/pages/Group/GroupsPage.vue';
import GroupPage from '@/pages/Group/GroupPage/GroupPage.vue';
import DevicePage from '@/pages/Device/DevicePage/DevicePage.vue';
import DevicesPage from '@/pages/Device/DevicesPage.vue';
import AlertsPage from '@/pages/Alerts/AlertsPage.vue';
import CompaniesPage from '@/pages/Company/CompaniesPage.vue';
import CompanyPage from '@/pages/Company/CompanyPage/CompanyPage.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/dashboard',
        name: 'Principal',
        component: DashboardPage
    },
    {
        path: '/configuration',
        name: 'Configuración',
        component: ConfigurationPage
    },
    {
        path: '/system/administrators',
        name: 'Administradores',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UsersPage
    },
    {
        path: '/system/administrators/:id',
        name: 'Datos del administrador',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UserPage
    },
    {
        path: '/companies',
        name: 'Empresas',
        component: CompaniesPage
    },
    {
        path: '/system/companies/admins',
        name: 'Administradores de empresa',
        props: { type: User.TYPE_COMPANY },
        component: UsersPage
    },
    {
        path: '/system/companies/admins/:id',
        name: 'Datos del administrador de empresas',
        props: { type: User.TYPE_COMPANY },
        component: UserPage
    },
    {
        path: '/companies/:id_company/admins/:id',
        name: 'Datos del administrador de empresa',
        props: { type: User.TYPE_COMPANY },
        component: UserPage
    },
    {
        path: '/companies/:id_company/users/:id',
        name: 'Datos del técnico',
        props: { type: User.TYPE_USER },
        component: UserPage
    },
    {
        path: '/companies/:id',
        name: 'Datos de la empresa',
        component: CompanyPage
    },
    {
        path: '/companies/:id_company/devices/:id',
        name: 'Datos del dispositivo de la compañia',
        component: DevicePage
    },
    {
        path: '/companies/:id_company/groups/:id',
        name: 'Datos del grupo de la compañia',
        component: GroupPage
    },
    {
        path: '/system/company',
        name: 'Mi empresa',
        component: CompanyPage
    },
    {
        path: '/users',
        name: 'Técnicos',
        props: { type: User.TYPE_USER },
        component: UsersPage
    },
    {
        path: '/users/:id',
        name: 'Datos del técnico',
        props: { type: User.TYPE_USER },
        component: UserPage
    },
    {
        path: '/groups',
        name: 'Grupos',
        component: GroupsPage
    },
    {
        path: '/groups/:id',
        name: 'Datos del grupo',
        component: GroupPage
    },
    {
        path: '/groups/:id_group/devices/:id',
        name: 'Datos del dispositivo del grupo',
        component: DevicePage
    },
    {
        path: '/devices',
        name: 'Dispositivos',
        component: DevicesPage
    },
    {
        path: '/devices/:id',
        name: 'Datos del dispositivo',
        component: DevicePage
    },
    {
        path: '/alerts',
        name: 'Alertas',
        component: AlertsPage
    },
    {
        path: '/system/roles',
        name: 'Roles',
        component: RolesPage
    },
    {
        path: '/system/roles/:id',
        name: 'Datos del Rol',
        component: RolePage
    },
    {
        path: '/profile',
        name: 'Mi perfil',
        component: ProfilePage
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundPage
    }
];
const router = new VueRouter({
    routes,
    mode: 'history'
});
export default router;
