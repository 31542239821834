import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import MaterialStatsCard from '@/components/Base/MaterialStatsCard/MaterialStatsCard.vue';
import StatsService from '@/services/StatsService';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let DashboardPage = class DashboardPage extends JSPage {
    constructor() {
        super(...arguments);
        this.statsService = new StatsService();
        this.stats = {};
    }
    created() {
        super.created();
    }
    main() {
        this.getStats();
    }
    getStats() {
        this.statsService.get().subscribe((stats) => {
            this.stats = stats;
        });
    }
    goToCompanies() {
        Router.goTo('/companies');
    }
    goToAlerts() {
        Router.goTo('/alerts');
    }
    goToDevices() {
        Router.goTo('/devices');
    }
    goToGroups() {
        Router.goTo('/groups');
    }
    goToUsers() {
        Router.goTo('/users');
    }
};
DashboardPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialStatsCard,
        }
    })
], DashboardPage);
export default DashboardPage;
