import router from '@/router';
import Base64 from './Base64';
import $ from 'jquery';
export default class Router {
    static init() {
        this.path = router.currentRoute.path;
    }
    static watchPath(callback) {
        Router.pathWatches.push(callback);
    }
    static watchParams(callback) {
        Router.paramsWatches.push(callback);
    }
    static update() {
        Router.path = router.currentRoute.path;
        Router.pathWatches.forEach(callback => callback(Router.path));
        Router.paramsWatches.forEach(callback => callback(Router.params));
    }
    static goTo(path, object, encode = false) {
        if ((router.currentRoute.path != path || object) &&
            !Router.excludedPaths.includes(router.currentRoute.path)) {
            let queryString = '';
            if (object) {
                if (router.currentRoute.path == path) {
                    object.hash = Math.floor((Math.random() * 1000000)) + 1;
                }
                queryString = Object.keys(object).map(key => key + '=' + object[key]).join('&');
                queryString = '?' + (encode ? 'q=' + Base64.encode(queryString) : queryString);
            }
            router.push(path + queryString, () => window.scrollTo(0, 0));
            if (!$('.navbar-toggler').hasClass('collapsed')) {
                $('.navbar-toggler').trigger('click');
            }
        }
    }
    static getParam(param, defaultValue = '') {
        if (router.currentRoute.params[param]) {
            return router.currentRoute.params[param];
        }
        return defaultValue.toString();
    }
    static hasParam(param) {
        return router.currentRoute.params[param] ? true : false;
    }
    static getQueryVar(varName, defaultValue = '') {
        if (router.currentRoute.query[varName]) {
            return router.currentRoute.query[varName];
        }
        return defaultValue;
    }
    static getQuery(decode = false) {
        let query = router.currentRoute.query;
        if (decode && query.q) {
            let decoded = Base64.decode(query.q);
            return this.parseQuery(decoded);
        }
        return query;
    }
    static addParamToURL(name, value) {
        router.currentRoute.params[name] = value.toString();
        Router.recreateURL();
    }
    static removeParamFromURL(name) {
        delete router.currentRoute.params[name];
        Router.recreateURL();
    }
    static addQueryToURL(name, value) {
        var query = Object.assign({}, router.currentRoute.query);
        if (query[name] && query[name] === value.toString()) {
            return;
        }
        query[name] = value.toString();
        Router.recreateURL(query);
    }
    static removeQueryFromURL(name) {
        var query = Object.assign({}, router.currentRoute.query);
        if (!query[name]) {
            return;
        }
        delete query[name];
        Router.recreateURL(query);
    }
    static recreateURL(query) {
        if (!query) {
            query = router.currentRoute.query;
        }
        router.replace({ params: router.currentRoute.params, query: query, hash: router.currentRoute.hash });
        // var params = '';
        // for( var elem in router.currentRoute.params ) {
        // 	if( elem !== 'id' ) {
        //         if( params !== '' ) {
        // 			params += '&';
        // 		}
        // 		params += elem + '=' + router.currentRoute.params[elem];
        // 	}
        // }
        // router.replace( router.currentRoute.path + ( params !== '' ? '?' : '' ) + params + router.currentRoute.hash );             
    }
    static loadJS(url, id, callback) {
        if (document.getElementById(id)) {
            return;
        }
        let script = document.createElement('script');
        script.setAttribute('src', url);
        script.setAttribute('id', id);
        if (callback) {
            script.addEventListener('load', () => { callback(); });
        }
        document.head.appendChild(script);
    }
    static goBack() {
        router.back();
    }
    static parseQuery(query) {
        var res = {};
        query = query.trim().replace(/^(\?|#|&)/, '');
        if (!query) {
            return res;
        }
        query.split('&').forEach(function (param) {
            var parts = param.replace(/\+/g, ' ').split('=');
            var key = decodeURIComponent(parts.shift().toString());
            var val = parts.length > 0
                ? decodeURIComponent(parts.join('='))
                : null;
            if (res[key] === undefined) {
                res[key] = val;
            }
            else if (Array.isArray(res[key])) {
                res[key].push(val);
            }
            else {
                res[key] = [res[key], val];
            }
        });
        return res;
    }
}
Router.excludedPaths = new Array();
Router.path = '';
Router.pathWatches = new Array();
Router.params = new Array();
Router.paramsWatches = new Array();
