export default class Trilean {
    constructor() {
        this._value = TrileanValue.UNDETERMINATED;
    }
    get value() {
        return this._value;
    }
    set value(value) {
        if (value === TrileanValue.TRUE || value === TrileanValue.FALSE || value === TrileanValue.UNDETERMINATED) {
            this._value = value;
        }
    }
    isUndeterminated() {
        return this._value === TrileanValue.UNDETERMINATED;
    }
}
export class TrileanValue {
}
TrileanValue.TRUE = true;
TrileanValue.FALSE = false;
TrileanValue.UNDETERMINATED = undefined;
